.hero-carousel .mySwiper{
    position: relative;
}
.hero-carousel .swiper-pagination{
    /* background-color: #00ffff; */
    /* display: block; */
    position: absolute;
    z-index: 100;
    bottom: 3%;
    left: -38%;
    
}
.swiper-pagination .my_sigle_bullet_white{
    background-color: white;
    border: 0px solid transparent;
    height: 0.8rem;
    width: 0.8rem;
    border-radius: 0;
    margin: 0 0.35rem;
}
.swiper-pagination .my_sigle_bullet_white.swiper-pagination-bullet-active{
    background-color: green;
    border: 0px solid transparent;
    height: 0.8rem;
    width: 0.8rem;
    border-radius: 0;
}

.swiper-pagination .my_sigle_bullet {
    background-color: transparent;
    border: 2px solid green;
    height: 0.8rem;
    width: 0.8rem;
}



.swiper-pagination .my_sigle_bullet.swiper-pagination-bullet-active {
    background-color: green;
    border: 2px solid green;
}

.my_sigle_bullet_color_1 {
    background-color: transparent;
    border: 2px solid rgb(194, 194, 194);
    height: 0.8rem;
    width: 0.8rem;
}

.my_sigle_bullet_color_1.swiper-pagination-bullet-active {
    background-color: rgb(194, 194, 194);
    border: 2px solid rgb(194, 194, 194);
}