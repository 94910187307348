:root {
    --Primary-color: #156C42;
    --navbar-background: #156C42;
    --navbar-text-color: #BBFCDD;
    --Primary-light: #1ea362;
}

html {
    font-size: 100%;
    box-sizing: border-box;
}

hr {
    margin: 0.8rem 0;

}


.tab button {
    background-color: inherit;
    outline: none;
    cursor: pointer;
    padding: 00.6rem 0.8rem;
    margin: 0.6rem;
    transition: 0.3s;
    font-size: 0.9rem;
}

/* Change background color of buttons on hover */
.tab button:hover {
    background-color: #ddd;
    outline: none;
}

/* Create an active/current tablink class */
.tab button .active {
    background-color: #ccc;
    outline: none;
}

/* Style the tab content */
.tabcontent {
    display: none;
    transition: all 0.3s ease-in-out;
}

.tabcontent:nth-child(1) {
    display: block;
}


/* dropwown navbar */

.menu {
    position: relative;
}

.anti_menu {
    position: absolute;
    width: fit-content;
    padding-top: 1rem;
    top: 100%;
    background-color: var(--Primary-color);
    transition: all 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: translateY(3rem);
    
}

.anti_menu ul {
    width: 100%;
    height: 100%;
}

.anti_menu ul li {
    width: 100%;
    height: 100%;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out,;
}

.anti_menu ul li:focus,
.anti_menu ul li:focus-within,
.anti_menu ul li:hover {
    background: var(--Primary-light);
   
}

.anti_menu li a {
    padding: 0.8rem 0.8rem;
    width: 15rem;
}

.menu:focus .anti_menu,
/* .menu:focus-within .anti_menu, */
.menu:hover .anti_menu {
    visibility: visible;
    height: auto;
    opacity: 1;
    transform: translateY(0);
    
}

.sub_menu {
    position: relative;
}

.sub_anti_menu {
    position: absolute;
    left: 100%;
    top: 0%;
    background-color: var(--Primary-color);
    visibility: hidden;
    height: 0;
    opacity: 0;
    transform: translateY(3rem);
    transition: all 0.3s ease-in-out;
}

.sub_anti_menu ul li a {
    width: 14rem;
}

.sub_menu:focus .sub_anti_menu,
/* .sub_menu:focus-within .sub_anti_menu, */
.sub_menu:hover .sub_anti_menu {
    visibility: visible;
    opacity: 1;
    height: auto;
    transform: translateY(0);
}



/* dropdown section end  */


/* read more pages */
.read_more_section .container .heading_read_more .head_line {
    border-left: 5px solid #ACACAC;
    padding: 0.2rem 0.7rem;
}

.read_more_section .container .row .col-lg-8 .heading_read_more .date_group {
    display: flex;
    margin-top: 0.5rem;
    margin-left: 0.8rem;
}

.read_more_section .container .row .col-lg-8 .heading_read_more .date_group img {
    height: 10px;
    width: 10px;
    color: #ACACAC;
    margin-top: 0.4rem;
}

.read_more_section .container .heading_read_more .date_group span {
    padding-left: 0.5rem;
}

.read_more_section .container .row .col-lg-8 .content h6 {
    font-size: 1.1rem;
    font-weight: 700;
}

.read_more_section .container .row .col-lg-8 img {
    margin-top: 2rem;
    height: 25rem;
    width: 50rem;
}

.read_more_section .container .row .col-lg-3 {
    margin-left: 1rem;

}

.read_more_section .container .row .col-lg-3 .content h4 {
    font-size: 1.5rem;
}

.read_more_section .container .row .col-lg-3 .content .hr1 {
    border-top: 1px solid black;
}

.read_more_section .container .row .col-lg-3 .content ul li .hr2 {
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.read_more_section .container .row .col-lg-3 .content ul {
    padding: 1rem;
    font-size: 0.8rem;
    font-weight: 300;
}








/* manage_profile_section */
.row .main_box .small_box {
    background-color: #E9ECEF;
    margin: 1rem;
}

.row .main_box .small_box:nth-child(1) {
    margin-top: 0;
}

.row .main_box .small_box img {
    width: 10rem;
    margin: 2rem 0;
}

.small_box small {
    display: block;

}

.small_box .scocials div {

    display: flex;

    justify-content: space-between;

    height: 10rem;
}

.small_box .scocials div .link {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.small_box .scocials div .link a {
    text-transform: none;
    color: black;
    padding: 0.5rem;
    margin-top: 0;
    margin: 0 0.5rem;

    text-align: left;
}

.small_box .scocials div .link a:nth-child(1) {
    margin-top: 1rem;
    margin-bottom: 0;
}

.small_box .scocials div .link a:nth-child(3) {
    margin-top: 0;
    margin-bottom: 1rem;
}

.small_box .scocials div .link_text a {
    text-align: right;
}

/* table section in manage_profile.html  */

.small_box .table_education {
    font-size: 0.68rem;
}



/* donation page  */
/* #donation{
    height: 100vh;
} */
#donation .container .form-box {
    /* width: 30rem; */
    width: 100%;
    height: 8rem;
    padding-bottom: 0;
    margin-bottom: 2rem;
}

#donation .container .form-box .min_box {
    padding-bottom: 0;
    width: 100%;
}




@media (max-width:1200px) {
    html {
        font-size: 90%;
    }

    /* manage profile  */
    .small_box .table_education {
        font-size: 0.6rem;
    }

    /* read more pages  */
    .read_more_section .container .row .col-lg-8 .content h6 {
        font-size: 1.1rem;
        font-weight: 700;
    }

}




@media (max-width:991px) {
    html {
        font-size: 80%;
    }

    /* manage profile  */
    .small_box .table_education {
        font-size: 1.2rem;
    }

    /* read more pages  */
    .read_more_section .container .row .col-lg-8 img {
        height: 20rem;
        width: 40rem;
    }

    .read_more_section .container .row .col-lg-8 .content h6 {
        font-size: 1.1rem;
        font-weight: 700;
    }

    /* dropdown section start*/
    .anti_menu {
        height: 0;
        position: relative;
        left: 1.5rem;
    }

    .anti_menu ul li a {
        width: 20rem;
    }

    .sub_anti_menu {
        height: 0;
        position: initial;
        padding-left: 1rem;
    }

    .sub_anti_menu ul li a {
        width: 20rem;
    }

    /* dropdown section end*/
}

@media (max-width:770px) {
    html {
        font-size: 70%;
    }

    /* manage profile  */
    .small_box .table_education {
        font-size: 1rem;
    }

    /* read more pages  */
    .read_more_section .container {
        position: relative;
        top: 12rem;
        height: 70rem;
    }

    .read_more_section .container .row .col-lg-8 .content h6 {
        font-size: 1.1rem;
        font-weight: 700;
    }

    /* dropdown section */

    /* donation page  */
    #donation {
        /* position: relative; */
    }
   
    #donation #form-check label {
        margin-top: 0.1rem;
        margin-left: 0.8rem;
        margin-right: 1rem;

    }


}

@media (max-width:540px) {
    html {
        font-size: 60%;
    }

    /* manage profile  */
    .small_box .table_education {
        font-size: 1rem;
    }

    /* read more pages  */

    .read_more_section .container {
        position: relative;
        top: 12rem;
        height: 60rem;
    }

    .read_more_section .container .row .col-lg-8 .content h6 {
        font-size: 1.1rem;
        font-weight: 700;
    }

    /* donation page  */
    #donation {
        /* position: relative;
        top: 16%;
        height: 70vh; */

    }

    #donation #form-check label {
        margin-top: 0.1rem;
        margin-left: 0.8rem;

    }

}

@media (max-width:420px) {
    html {
        font-size: 50%;
    }

    /* manage profile  */
    .small_box .table_education {
        font-size: 1rem;
    }

    .small_box .scocials {
        font-size: 0.8rem;
    }

    .small_box h2 {
        font-size: 1.5rem;
    }

    .small_box h2 {
        font-size: 1.5rem;
    }

    .small_box small {
        display: block;
    }

    /* read more pages  */
    .read_more_section .container {
        position: relative;
        top: 15rem;
        height: 75rem;
    }

    .read_more_section .container .row .col-lg-8 .content h6 {
        font-size: 1.1rem;
        font-weight: 700;
    }

    /* donation page  */
    #donation {
        /* position: relative;
        top: 17%;
        height: 100vh; */
    }

    #donation #form-check label {
        margin-top: 0.1rem;
        margin-left: 0.8rem;

    }

}

@media (max-width:360px) {
    html {
        font-size: 40%;
    }

    /* manage profile  */
    .small_box .table_education {
        font-size: 1.15rem;
    }

    /* read more pages  */

    .read_more_section .container .row .col-lg-8 .content h6 {
        font-size: 1.1rem;
        font-weight: 700;
    }

    /* donation page  */
    #donation {
        /* position: relative;
        top: 16%;
        height: 100vh; */
    }

    #donation #form-check label {
        margin-top: 0.3rem;
        margin-left: 1rem;

    }

}

@media (max-width:280px) {
    html {
        font-size: 30%;
    }

    /* manage profile  */
    .small_box .table_education {
        font-size: 1.2rem;
    }

    /* read more pages  */

    .read_more_section .container {
        position: relative;
        top: 28rem;
        height: 80rem;
    }

    .read_more_section .container .row .col-lg-8 .content h6 {
        font-size: 0.5rem;
        font-weight: 700;
    }

    /* donation page  */
    #donation {

        /* position: relative;
        top: 20%; */

    }

    #dontaion_cheakbox {
        margin-right: 0.3rem;
    }

    #donation #form-check label {
        margin-top: 0.5rem;
        margin-left: 2rem;

    }
}