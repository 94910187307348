:root {
    --Lighter-Color: #e7f4ff;
    --Second-Secondary-color: #a0b6c6;
    --Second-Secondary-color2: #E4D296;
    --Site-primary-color: #156C42;
}



* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.bg-green-primary {
    background-color: var(--Site-primary-color);
}
.btn-success{
    background-color: var(--Site-primary-color) !important;
    border: 0;
    transition: all 0.3s ease-in-out;
}

.btn-success:hover{
    background-color: var(--Second-Secondary-color2) !important;
    color: black !important;
}

.mygap{
    margin-top: 0;
}

/* .btn-hover-outline-green {
    transition: all 0.3s ease-in-out;
    color: white;
}

.btn-hover-outline-green:focus, 
.btn-hover-outline-green:focus-within, 
.btn-hover-outline-green:hover{
    background-color: white;
    color: black;
    border: 2px solid #156C42;
    outline: none;
} */



.donation:hover {
    border-color: white;
    background-color: white;
}

a {
    text-decoration: none;
    color: black;
    background-color: transparent;
}

.active_link {
    font-weight: bold;
}

.mybtn {
    color: black;
    transition: all 0.3s ease-in-out;
}

.mybtn:hover {
    color: green;
}

.hover_child {
    filter: grayscale(0%);
    transform: scale(1);
    transition: all 0.3s ease-in-out;
}

.hover_parent:hover .hover_child {
    filter: grayscale(100%);
    transform: scale(1.05);
}

/* Bradcrumb start  */
.banner-inner {
    position: relative;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.banner-inner::before {
    content: "";
    position: absolute;
    inset-inline-start: 0;
    top: 0;
    inline-size: 100%;
    block-size: 100%;
    background: var(--Second-Secondary-color);
    opacity: 0.5;
}

.breadcrumbs {
    opacity: 1;
    border: 1px solid #cbd2d9;
    border-radius: 0.3rem;
    display: inline-flex;
    overflow: hidden;
}

.breadcrumbs__item {
    background: #fff;
    color: #333;
    outline: none;
    padding: 0.75em 0.75em 0.75em 1.25em;
    position: relative;
    text-decoration: none;
    transition: background 0.2s linear;
}

.breadcrumbs__item:hover:after,
.breadcrumbs__item:hover {
    background: #edf1f5;
}

.breadcrumbs__item:focus:after,
.breadcrumbs__item:focus,
.breadcrumbs__item.is-active:focus {
    background: #323f4a;
    color: #fff;
}

.breadcrumbs__item:after,
.breadcrumbs__item:before {
    background: white;
    bottom: 0;
    clip-path: polygon(50% 50%, -50% -50%, 0 100%);
    content: "";
    left: 100%;
    position: absolute;
    top: 0;
    transition: background 0.2s linear;
    width: 1em;
    z-index: 1;
}

.breadcrumbs__item:before {
    background: #cbd2d9;
    margin-left: 1px;
}

.breadcrumbs__item:last-child {
    border-right: none;
}

.breadcrumbs__item.is-active {
    background: #edf1f5;
}

/* Bradcrumb end */

#blog {
    padding: 80px 0;
}

.fadeIN {
    visibility: hidden;
}

.bg-secondary-subtle {
    background: var(--Lighter-Color);
}

.d-block .fadeIN {
    animation: fadein 2s;
    animation-timing-function: ease-in-out;
    visibility: visible;
}


@keyframes fadein {
    0% {
        opacity: 0;
    }


    100% {
        opacity: 1;
    }
}

#hero .swiper {
    width: 100%;
    height: 100%;
    overflow: visible;
}

#hero .swiper-slide {
    color: #fff;
    border-radius: 0.5rem;
    position: relative;
}


#hero .fadeinleft,
#hero .fadeinright,
#hero .fadeindown {
    visibility: hidden;
}



#hero .swiper-slide-active .fadeinleft {
    animation: fadeInLeft 3s;
    /* animation-delay: 1s; */
    visibility: visible;
}

#hero .swiper-slide-active .fadeinright {
    animation: fadeInRight 3s;
    /* animation-delay: 1s; */
    visibility: visible;
}

#hero .swiper-slide-active .fadeindown {
    animation: fadeInDown 3s;
    /* animation-delay: 1s; */
    visibility: visible;
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(3rem);
    }

    30% {
        opacity: 0.3;
    }

    60% {
        opacity: 0.6;
    }

    90% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(-3rem);
    }

    30% {
        opacity: 0.3;
    }

    60% {
        opacity: 0.6;
    }

    90% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(3rem);
    }

    30% {
        opacity: 0.3;
    }

    60% {
        opacity: 0.6;
    }

    90% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* navbar hidding in mobile start */
.header_hide_in_Mobile {
    display: none;
}

.hide_in_Mobile {
    display: none;
}

.hide_in_pc {
    display: inline-block;
}

.same_height_width {
    width: 90%;
    min-width: 109px;
    height: 38px;

}

.My_menu_search_PC_hide {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: #FFF;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.My_menu_search_PC_hide svg {
    margin-left: 93%;
}

.My_menu_search_PC_hide input {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, .7);
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    color: #FFF;
    font-size: 14px;
    padding-left: 18px;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
}

.contact_menu_mob {
    width: 27rem;
    position: absolute;
    bottom: 0%;
}

.height_adjust{
    height: 28rem;
}

.team-image{
    height: 24rem;
}


@media (min-width:541px){
    .height_adjust{
        height: 29rem;
    }
    .team-image{
        height: 24rem;
    }
}
@media (min-width:572px){
    .height_adjust{
        height: 24rem;
    }
}
@media (min-width:770px){
    .height_adjust{
        height: 24rem;
    }
    .team-image{
        height: 18rem;
    }
}

@media (min-width:991px) {

    .hide_in_Mobile {
        display: block;
    }

    .header_hide_in_Mobile {
        display: flex;
    }

    .hide_in_pc {
        display: none;
    }

    .My_menu_search_PC_hide {
        display: none;
    }
    .height_adjust{
        height: 18rem;
    }
    .team-image{
        height: 22rem;
    }
    
}
@media (min-height:1000px) {
    .team-image{
        height: 16rem;
    }
}
@media (min-width:1200px){
    .height_adjust{
        height: 18rem;
    }
    .team-image{
        height: 16rem;
    }
}

@media (max-width:1020px) {
    #history {
        margin-top: 3rem;
    }

    .mygap{
        margin-top: 2rem;
    }

    
}


/* form page design start */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

:root {
    --deem-text-color: #6b89ff;
}

#container_form a {
    text-decoration: none !important;
    color: var(--deem-text-color) !important;

}

#container_form #webshine_link_container .webshine_link {
    font-weight: bold;
}

#container_form #webshine_link_container .imprtant_text_deem {
    color: var(--deem-text-color) !important;
}




#form_bg_trasnparennt {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, #222c63, #3f6e9d);
    /* background-image: url("https://source.unsplash.com/random/?mountain"); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;


}

#form_bg_trasnparennt::before {
    content: '';
    position: absolute;
    inset: 0;
    opacity: 0.8;
    background-color: rgb(0, 0, 0);
}

#container_form {
    color: rgb(255, 255, 255);
    opacity: 1;
    position: relative;
}

#container_form .details {
    height: 33rem;
    width: auto;
}

#container_form .brand_details {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    background: #222c63;
    padding: 2rem;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}

#container_form .brand_details .content {
    margin-top: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: justify;
    text-justify: inter-word;
    position: relative;
    color: var(--deem-text-color);
}

#container_form #webshine_link_container {
    position: absolute;
    z-index: 100;
    top: 115%;
    left: 0;
    right: 0;
}


#container_form .logo_container {
    text-align: center;
}


#container_form .bizzself_logo {

    width: 70%;

}

#container_form .form_section {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(0, 0, 0, 0.59); */
    background-image: url("https://source.unsplash.com/random/?mountain");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    position: relative;
    overflow: hidden;
}

#container_form .form_section::before {
    content: '';
    position: absolute;
    inset: 0;
    opacity: 0.8;
    background-color: rgb(0, 0, 0);
    overflow: hidden;
}

#container_form .form_section .form_section_inner {
    opacity: 1;
    z-index: 10;
    padding: 2rem;
    border-radius: 0.5rem;
    width: 80%;
    background-color: rgba(255, 255, 255, 0.07);
}

#container_form .form_section .form_section_inner .btn_custom {
    color: white;
    border-radius: 0.5rem;
    outline: none;
    border: 0;
    background: #222C63;
    transition: all 0.3s ease-in-out;
    width: 100%;
    padding: 0.56rem;
}

#container_form .form_section .form_section_inner .btn_custom:hover,
#container_form .form_section .form_section_inner .btn_custom:focus,
#container_form .form_section .form_section_inner .btn_custom:focus-within {
    background: white;
    color: #222C63;
}


@media (max-width:1010px) {
    #container_form .brand_details {
        display: none;
    }

    #container_form .form_section {
        border-radius: 0.5rem;
    }
}

/* form page design end */