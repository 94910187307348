@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Bengali:wght@100;200;300;400;500;600;700;800;900&display=swap');

/**************************************

Template Name: Akkhor 
Template URL: https://www.radiustheme.com/demo/html/psdboss/akkhor/
Description: School Management Admin Dashboard HTML Template
Author: PsdBosS
Author URL: https://themeforest.net/user/psdboss
Version: 1.0 

======================================  
        Table Of Contents
======================================
1. TYPOGRAPHY
2. BOOTSTRAP OVERWRITE
    2.1 Card
    2.2 Dropdown
    2.3 Forms
    2.4 Grid
    2.5 Media Object
    2.6 Table
    2.7 Modal
3. VENDOR CUSTOM STYLE
    3.1 Data Table
    3.2 Full Calender
    3.3 Select 2
4. ELEMENTS CUSTOM STYLE
    4.1 Animation
    4.2 Button
5. PAGE LAYOUT STYLE
    5.1 Content
    5.2 Default 
    5.3 Footer
    5.4 Header Menu
    5.5 Sidebar Menu
6. TEMPLATE STYLE
    6.1 Student Page Style
    6.2 Notice Board
    6.3 Messaging
    6.4 Account Settings
    6.5 UI Elements
    6.6 Login Page
7. DASHBOARD STYLES
    7.1 Dashboard Page One
    7.2 Dashboard Page Two
    7.3 Dashboard Page Three
    7.4 Dashboard Page Four
8. HELPER CLASSES
    8.1 Background
    8.2 Border
    8.3 Height
    8.4 Margin
    8.5 Misc
    8.6 Padding
    8.7 Position
    8.8 Text

**************************************/
/*=======================================================================
[01] TYPOGRAPHY
=========================================================================*/
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900);

/*=======================================================================
3. VENDOR CUSTOM STYLE
=========================================================================*/
/*=============== 3.1 Data Table ==================*/
.dataTables_wrapper .table {
    border-collapse: collapse;
    border: 1px solid #e1e1e1;
}

.dataTables_wrapper .table thead .sorting {
    background-image: none;
    position: relative;
}

.dataTables_wrapper .table thead .sorting:before {
    content: "\f0d8";
    font-family: "Font Awesome 5 Free";
    font-size: 14px;
    color: #bfbfbf;
    font-weight: 600;
    position: absolute;
    top: 43%;
    right: 0;
    transform: translateY(-50%);
}

.dataTables_wrapper .table thead .sorting:after {
    content: "\f0d7";
    font-family: "Font Awesome 5 Free";
    font-size: 14px;
    color: #bfbfbf;
    font-weight: 600;
    position: absolute;
    bottom: 43%;
    right: 0;
    transform: translateY(50%);
}

.dataTables_wrapper .table thead .sorting_asc {
    background-image: none;
    position: relative;
}

.dataTables_wrapper .table thead .sorting_asc:after {
    content: "\f0d8";
    font-family: "Font Awesome 5 Free";
    font-size: 14px;
    color: #111111;
    font-weight: 600;
    position: absolute;
    bottom: 53%;
    right: 0;
    transform: translateY(50%);
}

.dataTables_wrapper .table thead .sorting_desc {
    background-image: none;
    position: relative;
}

.dataTables_wrapper .table thead .sorting_desc:after {
    content: "\f0d7";
    font-family: "Font Awesome 5 Free";
    font-size: 14px;
    color: #111111;
    font-weight: 600;
    position: absolute;
    bottom: 43%;
    right: 0;
    transform: translateY(50%);
}

.dataTables_wrapper .table thead tr th {
    border-bottom: 2px solid #e1e1e1;
    border-top: none;
    font-size: 16px;
    font-weight: 500;
    color: #111111;
    padding: 14px 15px;
}

@media only screen and (max-width: 991px) {
    .dataTables_wrapper .table thead tr th {
        font-size: 15px;
    }
}

.dataTables_wrapper .table tbody tr {
    border: 1px solid #e1e1e1;
}

.dataTables_wrapper .table tbody tr td {
    font-size: 15px;
    font-weight: 400;
    padding: 9px 15px;
    vertical-align: middle;
}

.dataTables_wrapper .table tbody tr td .dropdown .dropdown-toggle span:before {
    color: #bcbcbc;
    font-weight: 700;
}

.dataTables_wrapper .table tbody tr td .dropdown .dropdown-toggle:after {
    border: none;
}

.dataTables_wrapper .table tbody tr td .dropdown .dropdown-menu {
    border: none;
    padding: 15px 0 18px;
}

.dataTables_wrapper .table tbody tr td .dropdown .dropdown-menu .dropdown-item {
    font-size: 15px;
    padding: 7px 20px;
}

.dataTables_wrapper .table tbody tr td .dropdown .dropdown-menu .dropdown-item i {
    margin-right: 14px;
    width: 22px;
}

.dataTables_wrapper .dataTables_paginate {
    padding-top: 20px;
}

.dataTables_wrapper .dataTables_paginate a.paginate_button.previous {
    margin-right: 10px;
}

.dataTables_wrapper .dataTables_paginate span a.paginate_button {
    border: 1px solid #ffae01;
    padding: 8px 18px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.dataTables_wrapper .dataTables_paginate span a.paginate_button:active {
    box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate span a.paginate_button:hover {
    background: -webkit-gradient(linear, left top, right top, from(#ff9d01), to(#ffae01));
    background: -webkit-linear-gradient(left, #ff9d01, #ffae01);
    background: -o-linear-gradient(left, #ff9d01, #ffae01);
    background: linear-gradient(to right, #ff9d01, #ffae01);
    border-color: #ffae01;
}

.dataTables_wrapper .dataTables_paginate span .paginate_button.current {
    background: -webkit-gradient(linear, left top, right top, from(#ff9d01), to(#ffae01));
    background: -webkit-linear-gradient(left, #ff9d01, #ffae01);
    background: -o-linear-gradient(left, #ff9d01, #ffae01);
    background: linear-gradient(to right, #ff9d01, #ffae01);
    border: 1px solid #ffae01;
    padding: 8px 18px;
}

.dataTables_wrapper .dataTables_paginate span .paginate_button.current:hover {
    background: transparent;
    border-color: #ffae01;
}

/*=============== 3.2 Full Calender ==================*/
.fc-calender .fc-header-toolbar .fc-left {
    width: 50%;
    margin-bottom: 30px;
}

.fc-calender .fc-header-toolbar .fc-left h2 {
    font-size: 18px;
    font-weight: 600;
    color: #111111;
}

.fc-calender .fc-header-toolbar .fc-right {
    margin-bottom: 30px;
    width: 50%;
}

.fc-calender .fc-header-toolbar .fc-right .fc-button-group {
    float: right;
}

.fc-calender .fc-header-toolbar .fc-right .fc-button-group .fc-button {
    background: none;
    box-shadow: none;
    border: none;
    padding: 0;
}

.fc-calender .fc-header-toolbar .fc-right .fc-button-group .fc-button:hover {
    color: #cdcdcd;
}

.fc-calender .fc-header-toolbar .fc-right .fc-button-group .fc-button:focus {
    outline: none;
}

.fc-calender .fc-header-toolbar .fc-center .fc-button-group {
    background-color: #f3f4f5;
    border-radius: 40px;
}

.fc-calender .fc-header-toolbar .fc-center .fc-button-group .fc-button {
    background-image: none;
    box-shadow: none;
    border: none;
    background: none;
    height: auto;
    font-size: 14px;
    color: #a5a5a5;
    text-transform: capitalize;
    padding: 8px 30px;
}

.fc-calender .fc-header-toolbar .fc-center .fc-button-group .fc-button:focus {
    outline: none;
}

@media only screen and (max-width: 479px) {
    .fc-calender .fc-header-toolbar .fc-center .fc-button-group .fc-button {
        padding: 8px 12px;
    }
}

.fc-calender .fc-header-toolbar .fc-center .fc-button-group .fc-button.fc-state-active {
    background-color: #f50057;
    color: #ffffff;
    border-radius: 40px;
    box-shadow: 0px 4px 10px 0px rgba(255, 151, 188, 0.75);
}

.fc-calender .fc-view-container .fc-month-view .fc-event-container {
    display: block;
}

.fc-calender .fc-view-container .fc-month-view .fc-event-container .fc-day-grid-event {
    color: #fff;
}

@media only screen and (max-width: 767px) {
    .fc-calender .fc-view-container .fc-month-view .fc-event-container .fc-day-grid-event {
        display: none;
    }
}

.fc-calender .fc-view-container .fc-month-view .fc-day-grid-container {
    height: auto !important;
}

.fc-calender .fc-view-container .fc-month-view .fc-day-grid-container .fc-week {
    min-height: 30px !important;
}

.fc-calender .fc-view-container .fc-month-view table,
.fc-calender .fc-view-container .fc-basicDay-view table {
    font-size: 14px;
    color: #444444;
}

.fc-calender .fc-view-container .fc-month-view table .fc-head tr .fc-head-container,
.fc-calender .fc-view-container .fc-basicDay-view table .fc-head tr .fc-head-container {
    border: none;
}

.fc-calender .fc-view-container .fc-month-view table .fc-head tr .fc-head-container .fc-row,
.fc-calender .fc-view-container .fc-basicDay-view table .fc-head tr .fc-head-container .fc-row {
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 10px;
}

.fc-calender .fc-view-container .fc-month-view table .fc-head tr .fc-head-container .fc-row .fc-day-header,
.fc-calender .fc-view-container .fc-basicDay-view table .fc-head tr .fc-head-container .fc-row .fc-day-header {
    border: none;
    text-align: center;
    padding-bottom: 15px;
}

@media only screen and (max-width: 479px) {

    .fc-calender .fc-view-container .fc-month-view table .fc-head tr .fc-head-container .fc-row .fc-day-header,
    .fc-calender .fc-view-container .fc-basicDay-view table .fc-head tr .fc-head-container .fc-row .fc-day-header {
        font-size: 13px;
    }
}

.fc-calender .fc-view-container .fc-month-view table .fc-body tr td,
.fc-calender .fc-view-container .fc-basicDay-view table .fc-body tr td {
    border: none;
    text-align: center;
}

.fc-calender .fc-view-container .fc-month-view table .fc-body tr td .fc-scroller .fc-day-grid .fc-week .fc-content-skeleton .fc-day-top .fc-day-number,
.fc-calender .fc-view-container .fc-basicDay-view table .fc-body tr td .fc-scroller .fc-day-grid .fc-week .fc-content-skeleton .fc-day-top .fc-day-number {
    float: initial;
}

@media only screen and (max-width: 479px) {
    .fc-calender .fc-view-container .fc-basicWeek-view .fc-head-container .fc-day-header {
        font-size: 10px;
    }
}

/*=============== 3.3 Select 2 ==================*/
.select2-container .select2-selection--single {
    background-color: #f0f1f3;
    border-color: #f0f1f3;
    height: 50px;
}

.select2-container .select2-selection--single:focus {
    outline: none;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
    border: 1px solid #aaaaaa;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    line-height: 50px;
    color: #b4b4b4;
    padding-left: 20px;
    padding-right: 30px;
    font-size: 15px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 50px;
    top: 0;
    right: 15px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #000 transparent transparent transparent;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #000 transparent;
}

.select2-container--default .select2-search--dropdown .select2-search__field:focus {
    outline: none;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #71bfe4;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #444;
    color: #fff;
}

.select2-results__option {
    font-size: 15px;
}

/*=======================================================================
4. ELEMENTS CUSTOM STYLE
=========================================================================*/
/*=============== 4.1 Animation ==================*/
@-webkit-keyframes dropdownanimate {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
        -webkit-transform: translate3d(0, 0px, 0);
        transform: translate3d(0, 0px, 0);
    }
}

@keyframes dropdownanimate {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
        -webkit-transform: translate3d(0, 0px, 0);
        transform: translate3d(0, 0px, 0);
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 #111111;
        box-shadow: 0 0 0 0 #111111;
    }

    40% {
        -webkit-box-shadow: 0 0 0 30px rgba(255, 194, 17, 0);
        box-shadow: 0 0 0 30px rgba(255, 194, 17, 0);
    }

    70% {
        -webkit-box-shadow: 0 0 0 30px rgba(255, 194, 17, 0);
        box-shadow: 0 0 0 30px rgba(255, 194, 17, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 194, 17, 0);
        box-shadow: 0 0 0 0 rgba(255, 194, 17, 0);
    }
}

@keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 #111111;
        box-shadow: 0 0 0 0 #111111;
    }

    40% {
        -webkit-box-shadow: 0 0 0 30px rgba(255, 194, 17, 0);
        box-shadow: 0 0 0 30px rgba(255, 194, 17, 0);
    }

    70% {
        -webkit-box-shadow: 0 0 0 30px rgba(255, 194, 17, 0);
        box-shadow: 0 0 0 30px rgba(255, 194, 17, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 194, 17, 0);
        box-shadow: 0 0 0 0 rgba(255, 194, 17, 0);
    }
}

/*=============== 4.2 Button ==================*/
.fw-btn-fill {
    overflow: hidden;
    position: relative;
    display: inline-block;
    height: 44px;
    width: 100%;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 1px;
    padding: 10px;
    cursor: pointer;
    z-index: 9;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.fw-btn-fill:hover {
    background: #042954;
}

.fw-btn-fill:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

.btn-fill-lg {
    display: inline-block;
    border: none;
    background-color: transparent;
    border-radius: 4px;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 1px;
    padding: 12px 45px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.btn-fill-lg:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

.btn-fill-xl {
    display: inline-block;
    border: none;
    background-color: transparent;
    padding: 12px 55px;
    cursor: pointer;
}

.btn-fill-xl:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

.btn-fill-lmd {
    display: inline-block;
    border: none;
    background-color: transparent;
    padding: 12px 35px;
    cursor: pointer;
}

.btn-fill-lmd:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

.btn-fill-md {
    display: inline-block;
    border: none;
    background-color: transparent;
    padding: 12px 30px;
    cursor: pointer;
}

.btn-fill-md:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

.btn-fill-sm {
    display: inline-block;
    border: none;
    background-color: transparent;
    padding: 12px 25px;
    cursor: pointer;
}

.btn-fill-sm:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

.btn-hover-bluedark:hover {
    background: #042954;
}

.btn-hover-yellow:hover {
    background: #ff9d01;
}

/*=======================================================================
5. PAGE LAYOUT STYLE
=========================================================================*/
/*=============== 5.1 Content ==================*/
.breadcrumbs-area {
    padding: 34px 0 35px;
    position: relative;
    z-index: 1;
}

.breadcrumbs-area h3 {
    font-weight: 500;
    text-transform: capitalize;
    color: #111111;
    margin-bottom: 8px;
}

.breadcrumbs-area ul li {
    display: inline-block;
    color: #fea801;
    font-size: 16px;
    position: relative;
    margin-right: 15px;
    padding-right: 10px;
}

.breadcrumbs-area ul li:before {
    content: "\f107";
    font-family: Flaticon;
    position: absolute;
    font-size: 10px;
    font-weight: 600;
    right: -10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 5;
    color: #fea801;
}

.breadcrumbs-area ul li a {
    color: #646464;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.breadcrumbs-area ul li a:hover {
    color: #fea801;
}

.breadcrumbs-area ul li:last-child {
    margin-right: 0;
    padding-right: 0;
}

.breadcrumbs-area ul li:last-child:before {
    display: none;
}

#preloader {
    /* background: #ffffff url('img/preloader.gif') no-repeat scroll center center; */
    height: 100%;
    left: 0;
    overflow: visible;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999999;
}

#scrollUp {
    background-color: #042954;
    bottom: 20px;
    color: #ffffff;
    display: block;
    font-size: 18px;
    height: 44px;
    line-height: 40px;
    position: fixed;
    right: 20px;
    text-align: center;
    text-decoration: none;
    width: 44px;
    z-index: 99999;
    border: 2px solid #111111;
    border-radius: 50%;
    transition: all 0.3s ease-out;
}

#scrollUp i {
    color: #fff;
}

#scrollUp:hover,
#scrollUp:focus {
    background-color: rgba(11, 11, 11, 0.3);
}

#scrollUp:hover i,
#scrollUp:focus i {
    color: #ffffff;
}

#scrollUp {
    animation: blinker 10s infinite;
}

.heading-layout1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    border: none;
    margin-bottom: 12px;
}

.heading-layout1 .item-title h3 {
    color: #111111;
    font-weight: 500;
    margin-bottom: 0;
}

.heading-layout1 .dropdown .dropdown-toggle {
    color: #bcbcbc;
    font-size: 36px;
    display: inline-block;
    line-height: 1;
    padding-bottom: 15px;
    position: relative;
    right: -10px;
}

.heading-layout1 .dropdown .dropdown-toggle:after {
    border: none;
}

.heading-layout1 .dropdown .dropdown-menu {
    border: none;
    padding: 15px 0 18px;
}

.heading-layout1 .dropdown .dropdown-menu .dropdown-item {
    font-size: 15px;
    padding: 7px 20px;
}

.heading-layout1 .dropdown .dropdown-menu .dropdown-item i {
    margin-right: 14px;
    width: 22px;
}

/*=============== 5.2 Default ==================*/
a {
    text-decoration: none;
}

a:active,
a:hover,
a:focus {
    text-decoration: none;
}

a:active,
a:hover,
a:focus {
    outline: 0 none;
}

img {
    max-width: 100%;
    height: auto;
}

ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

.bg-common {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.form-response .alert-danger,
.form-response .alert.alert-info,
.form-response .alert.alert-success {
    margin-top: 10px;
    margin-bottom: 0;
}

.form-response .alert-danger p,
.form-response .alert.alert-info p,
.form-response .alert.alert-success p {
    margin-bottom: 0;
}

.tooltip-inner {
    font-size: 12px;
    padding: 5px 10px;
}

/*=============== 5.3 Footer ==================*/
.footer-wrap-layout1 {
    padding: 2rem 0 4rem;
}

.copyright {
    color: #707070;
}

@media only screen and (max-width: 767px) {
    .copyright {
        text-align: center;
    }
}

.copyright a {
    color: #111111;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.copyright a:hover {
    color: #707070;
}

/*=============== 5.4 Header Menu ==================*/
.header-menu-one {
    -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
}

.nav-bar-header-one {
    background: linear-gradient(to right, #ff9d01, #ffaa01);
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 26rem;
    margin-top: -5px;
    margin-bottom: -5px;
    margin-left: -1rem;
}

@media only screen and (max-width: 767px) {
    .nav-bar-header-one {
        display: block;
        text-align: center;
        min-width: 20rem;
    }
}

.nav-bar-header-one .header-logo {
    padding-left: 10px;
}

@media only screen and (max-width: 767px) {
    .nav-bar-header-one .header-logo {
        padding-left: 0;
        padding-right: 12px;
    }
}

.nav-bar-header-one .header-logo a {
    display: block;
    padding: 10px 0;
}

@media only screen and (max-width: 767px) {
    .nav-bar-header-one .toggle-button {
        display: none;
    }
}

.nav-bar-header-one .toggle-button .item-link {
    padding: 26px 15px;
    display: block;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.nav-bar-header-one .toggle-button .item-link .btn-icon-wrap {
    width: 28px;
    height: 18px;
    display: block;
    position: relative;
}

.nav-bar-header-one .toggle-button .item-link .btn-icon-wrap span {
    position: absolute;
    right: 0;
    height: 2px;
    width: 100%;
    background-color: #ffffff;
    display: block;
    opacity: 1;
    transform: rotate(0deg);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.nav-bar-header-one .toggle-button .item-link .btn-icon-wrap span:nth-child(1n) {
    top: 0;
}

.nav-bar-header-one .toggle-button .item-link .btn-icon-wrap span:nth-child(2n) {
    width: 80%;
    top: 8px;
}

.nav-bar-header-one .toggle-button .item-link .btn-icon-wrap span:nth-child(3n) {
    top: 16px;
}

.nav-bar-header-one .toggle-button .item-link:focus {
    outline: none;
}

.nav-bar-header-two {
    background: linear-gradient(to right, #ff9d01, #ffaa01);
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 18rem;
    margin-top: -5px;
    margin-bottom: -5px;
    margin-left: -10px;
    padding: 11px 0;
}

.mobile-nav-bar .navbar-toggler {
    font-size: 20px;
    color: #444444;
    padding: 15px 10px;
}

.mobile-nav-bar .navbar-toggler:focus {
    outline: none;
}

.mobile-nav-bar .pulse-animation i {
    animation: pulse 1500ms infinite;
    border-radius: 50%;
}

.header-main-menu {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 20px 0 50px;
}

@media only screen and (min-width: 768px) {
    .header-main-menu .navbar-nav {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.header-main-menu .navbar-nav .navbar-item {
    margin-right: 28px;
}

@media only screen and (max-width: 767px) {
    .header-main-menu .navbar-nav .navbar-item {
        margin-right: 0;
        border-bottom: 1px solid #e8e3e3;
    }

    .header-main-menu .navbar-nav .navbar-item:first-child {
        margin-top: 15px;
    }

    .header-main-menu .navbar-nav .navbar-item:last-child {
        margin-bottom: 10px;
        border-bottom: none;
    }

    .header-main-menu .navbar-nav .navbar-item .navbar-nav-link {
        padding: 15px 0;
        display: flex !important;
        align-items: center;
    }
}

.header-main-menu .navbar-nav .navbar-item:last-child {
    margin-right: 0;
}

@media only screen and (max-width: 991px) {
    .header-main-menu .navbar-nav .header-search-bar {
        display: none;
    }
}

.header-main-menu .navbar-nav .header-search-bar .stylish-input-group {
    border: none;
    height: 60px;
    width: 400px;
}

@media only screen and (max-width: 1199px) {
    .header-main-menu .navbar-nav .header-search-bar .stylish-input-group {
        width: 265px;
    }
}

.header-main-menu .navbar-nav .header-search-bar .stylish-input-group .form-control {
    border: none;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    color: #111111;
    font-size: 16px;
    height: 60px;
}

.header-main-menu .navbar-nav .header-search-bar .stylish-input-group .input-group-addon {
    display: flex;
    padding-right: 11px;
    border: none;
    border-radius: 0;
    background: transparent !important;
}

.header-main-menu .navbar-nav .header-search-bar .stylish-input-group .input-group-addon button {
    cursor: pointer;
    background: transparent;
    border: 0;
    padding: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.header-main-menu .navbar-nav .header-search-bar .stylish-input-group .input-group-addon button span:before {
    color: #bebebe;
    font-size: 24px;
    margin-left: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.header-main-menu .navbar-nav .header-search-bar .stylish-input-group .input-group-addon button:focus {
    outline: none;
}

.header-main-menu .navbar-nav .header-search-bar .stylish-input-group input.form-control::-webkit-input-placeholder,
.header-main-menu .navbar-nav .header-search-bar .stylish-input-group textarea.form-control::-webkit-input-placeholder {
    color: #b1b1b1;
}

.header-main-menu .navbar-nav .header-search-bar .stylish-input-group input.form-control:-moz-placeholder,
.header-main-menu .navbar-nav .header-search-bar .stylish-input-group textarea.form-control:-moz-placeholder {
    color: #b1b1b1;
}

.header-main-menu .navbar-nav .header-search-bar .stylish-input-group input.form-control:-ms-input-placeholder,
.header-main-menu .navbar-nav .header-search-bar .stylish-input-group textarea.form-control:-ms-input-placeholder {
    color: #b1b1b1;
}

.header-main-menu .navbar-nav .header-language {
    margin-right: 42px;
    position: relative;
}

.header-main-menu .navbar-nav .header-language .navbar-nav-link {
    font-size: 15px;
    color: #444444;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 20px 0 12px;
}

.header-main-menu .navbar-nav .header-language .navbar-nav-link i {
    font-size: 18px;
    color: #ffab01;
    margin-right: 10px;
}

.header-main-menu .navbar-nav .header-language .navbar-nav-link:after {
    content: "\f107";
    font-family: Font Awesome\ 5 Free;
    font-weight: 600;
    font-size: 14px;
    border: none;
    position: absolute;
    top: 0;
    right: 9px;
}

@media only screen and (max-width: 767px) {
    .header-main-menu .navbar-nav .header-language .navbar-nav-link:after {
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        height: auto;
        width: auto;
    }
}

@media only screen and (max-width: 767px) {
    .header-main-menu .navbar-nav .header-language .navbar-nav-link {
        padding: 10px 0;
    }
}

.header-main-menu .navbar-nav .header-language .dropdown-menu {
    top: 46px;
    border: none;
    animation: dropdownanimate 200ms ease-in;
}

@media only screen and (max-width: 767px) {
    .header-main-menu .navbar-nav .header-language .dropdown-menu {
        animation: none;
    }
}

.header-main-menu .navbar-nav .header-message {
    margin-right: 38px;
}

@media only screen and (max-width: 767px) {
    .header-main-menu .navbar-nav .header-message {
        margin-right: 0;
    }
}

.header-main-menu .navbar-nav .header-message .navbar-nav-link {
    position: relative;
    color: #828282;
    font-size: 20px;
    display: block;
}

.header-main-menu .navbar-nav .header-message .navbar-nav-link span {
    position: absolute;
    top: -14px;
    right: -20px;
    height: 30px;
    width: 30px;
    line-height: 28px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    background-color: #2ad7c5;
    border: 2px solid #ffffff;
    border-radius: 50%;
    display: block;
    text-align: center;
    -webkit-box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 767px) {
    .header-main-menu .navbar-nav .header-message .navbar-nav-link span {
        top: 47%;
        right: 0;
        transform: translateY(-50%);
    }
}

.header-main-menu .navbar-nav .header-message .navbar-nav-link:after {
    display: none;
}

.header-main-menu .navbar-nav .header-message .dropdown-menu {
    min-width: 350px;
    border: none;
    top: 51px;
    animation: dropdownanimate 200ms ease-in;
}

@media only screen and (max-width: 767px) {
    .header-main-menu .navbar-nav .header-message .dropdown-menu {
        animation: none;
        margin-bottom: 15px;
        min-width: 200px;
    }
}

.header-main-menu .navbar-nav .header-message .dropdown-menu .item-header {
    text-align: center;
    background-color: #2ad7c5;
    padding: 20px 25px;
    border-radius: 4px 4px 0 0;
    position: relative;
}

.header-main-menu .navbar-nav .header-message .dropdown-menu .item-header:after {
    content: "";
    height: 0;
    width: 0;
    border-bottom: 10px solid #2ad7c5;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    position: absolute;
    top: -8px;
    right: 14px;
}

@media only screen and (max-width: 767px) {
    .header-main-menu .navbar-nav .header-message .dropdown-menu .item-header:after {
        display: none;
    }
}

.header-main-menu .navbar-nav .header-message .dropdown-menu .item-header .item-title {
    margin-bottom: 0;
    color: #fff;
}

.header-main-menu .navbar-nav .header-message .dropdown-menu .item-content {
    padding: 20px 25px;
}

.header-main-menu .navbar-nav .header-message .dropdown-menu .item-content .media .item-img {
    border-radius: 50%;
}

.header-main-menu .navbar-nav .header-message .dropdown-menu .item-content .media .item-img img {
    border-radius: 50%;
}

.header-main-menu .navbar-nav .header-message .dropdown-menu .item-content .media .author-online {
    position: relative;
}

.header-main-menu .navbar-nav .header-message .dropdown-menu .item-content .media .author-online:after {
    content: "";
    height: 10px;
    width: 10px;
    background-color: #08b13c;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 2px;
}

.header-main-menu .navbar-nav .header-message .dropdown-menu .item-content .media .media-body .item-title {
    margin-bottom: 4px;
}

.header-main-menu .navbar-nav .header-message .dropdown-menu .item-content .media .media-body .item-title a {
    display: block;
}

.header-main-menu .navbar-nav .header-message .dropdown-menu .item-content .media .media-body .item-title a .item-name {
    font-size: 14px;
    font-weight: 600;
    color: #444444;
    display: inline-block;
}

.header-main-menu .navbar-nav .header-message .dropdown-menu .item-content .media .media-body .item-title a .item-time {
    float: right;
    font-size: 14px;
    color: #848484;
}

.header-main-menu .navbar-nav .header-message .dropdown-menu .item-content .media .media-body p {
    font-size: 14px;
}

.header-main-menu .navbar-nav .header-notification .navbar-nav-link {
    position: relative;
    color: #828282;
    font-size: 20px;
    display: block;
}

.header-main-menu .navbar-nav .header-notification .navbar-nav-link span {
    position: absolute;
    top: -14px;
    right: -20px;
    height: 30px;
    width: 30px;
    line-height: 28px;
    color: #ffffff;
    font-size: 14px;
    background-color: #ff3131;
    border: 2px solid #ffffff;
    border-radius: 50%;
    display: block;
    text-align: center;
    -webkit-box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 767px) {
    .header-main-menu .navbar-nav .header-notification .navbar-nav-link span {
        top: 47%;
        right: 0;
        transform: translateY(-50%);
    }
}

.header-main-menu .navbar-nav .header-notification .navbar-nav-link:after {
    display: none;
}

.header-main-menu .navbar-nav .header-notification .dropdown-menu {
    min-width: 275px;
    border: none;
    top: 51px;
    animation: dropdownanimate 200ms ease-in;
}

@media only screen and (max-width: 767px) {
    .header-main-menu .navbar-nav .header-notification .dropdown-menu {
        animation: none;
        margin-bottom: 15px;
        min-width: 200px;
    }
}

.header-main-menu .navbar-nav .header-notification .dropdown-menu .item-header {
    text-align: center;
    background-color: #ff3131;
    padding: 20px 25px;
    border-radius: 4px 4px 0 0;
    position: relative;
}

.header-main-menu .navbar-nav .header-notification .dropdown-menu .item-header:after {
    content: "";
    height: 0;
    width: 0;
    border-bottom: 10px solid #ff3131;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    position: absolute;
    top: -8px;
    right: 14px;
}

@media only screen and (max-width: 767px) {
    .header-main-menu .navbar-nav .header-notification .dropdown-menu .item-header:after {
        display: none;
    }
}

.header-main-menu .navbar-nav .header-notification .dropdown-menu .item-header .item-title {
    margin-bottom: 0;
    color: #fff;
}

.header-main-menu .navbar-nav .header-notification .dropdown-menu .item-content {
    padding: 20px 25px;
}

.header-main-menu .navbar-nav .header-notification .dropdown-menu .item-content .media {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 14px;
    margin-bottom: 18px;
}

.header-main-menu .navbar-nav .header-notification .dropdown-menu .item-content .media:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
}

.header-main-menu .navbar-nav .header-notification .dropdown-menu .item-content .media .item-icon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    line-height: 31px;
    text-align: center;
}

.header-main-menu .navbar-nav .header-notification .dropdown-menu .item-content .media .item-icon i {
    color: #ffffff;
    font-size: 12px;
}

.header-main-menu .navbar-nav .header-notification .dropdown-menu .item-content .media .media-body .post-title {
    font-size: 14px;
    font-weight: 500;
    color: #444444;
}

.header-main-menu .navbar-nav .header-notification .dropdown-menu .item-content .media .media-body span {
    font-size: 12px;
}

.header-main-menu .navbar-nav .header-admin .navbar-nav-link {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .header-main-menu .navbar-nav .header-admin .navbar-nav-link {
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}

.header-main-menu .navbar-nav .header-admin .navbar-nav-link .admin-img img {
    border-radius: 50%;
}

.header-main-menu .navbar-nav .header-admin .navbar-nav-link .admin-title {
    text-align: right;
    padding-right: 26px;
}

@media only screen and (max-width: 767px) {
    .header-main-menu .navbar-nav .header-admin .navbar-nav-link .admin-title {
        padding: 0 0 0 15px;
        text-align: left;
    }
}

.header-main-menu .navbar-nav .header-admin .navbar-nav-link .admin-title .item-title {
    font-size: 15px;
    color: #111111;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 0;
}

.header-main-menu .navbar-nav .header-admin .navbar-nav-link .admin-title span {
    font-size: 13px;
    color: #646464;
    display: block;
}

.header-main-menu .navbar-nav .header-admin .navbar-nav-link:after {
    color: #999898;
    position: absolute;
    top: 8px;
    right: 47px;
    margin: 0;
}

@media only screen and (max-width: 767px) {
    .header-main-menu .navbar-nav .header-admin .navbar-nav-link:after {
        top: 25px;
        right: 10px;
    }
}

.header-main-menu .navbar-nav .header-admin .dropdown-menu {
    min-width: 250px;
    border: none;
    top: 54px;
    animation: dropdownanimate 200ms ease-in;
}

@media only screen and (max-width: 767px) {
    .header-main-menu .navbar-nav .header-admin .dropdown-menu {
        animation: none;
        margin-bottom: 15px;
        min-width: 200px;
    }
}

.header-main-menu .navbar-nav .header-admin .dropdown-menu .item-header {
    text-align: center;
    background-color: #ffa001;
    padding: 20px 25px;
    border-radius: 4px 4px 0 0;
    position: relative;
}

.header-main-menu .navbar-nav .header-admin .dropdown-menu .item-header:after {
    content: "";
    height: 0;
    width: 0;
    border-bottom: 10px solid #ffa001;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    position: absolute;
    top: -8px;
    right: 14px;
}

@media only screen and (max-width: 767px) {
    .header-main-menu .navbar-nav .header-admin .dropdown-menu .item-header:after {
        display: none;
    }
}

.header-main-menu .navbar-nav .header-admin .dropdown-menu .item-header .item-title {
    margin-bottom: 0;
    color: #fff;
}

.header-main-menu .navbar-nav .header-admin .dropdown-menu .item-content .settings-list {
    padding: 10px 0;
}

.header-main-menu .navbar-nav .header-admin .dropdown-menu .item-content .settings-list li a {
    color: #646464;
    font-size: 15px;
    display: block;
    padding: 8px 25px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.header-main-menu .navbar-nav .header-admin .dropdown-menu .item-content .settings-list li a i:before {
    margin-left: 0;
    margin-right: 10px;
    font-size: 15px;
    font-weight: 600;
    color: #444444;
}

.header-main-menu .navbar-nav .header-admin .dropdown-menu .item-content .settings-list li a:hover {
    background-color: #f6f6f6;
}

/*=============== 5.5 Sidebar Menu ==================*/
.sidebar-menu-one {
    width: 26rem;
    position: fixed;
    top: 0;
    bottom: 0;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    z-index: 10;
}

.sidebar-menu-one .sidebar-menu-content {
    border: none;
    border-radius: 0;
    background-color: transparent;
    padding-bottom: 3rem;
    position: fixed;
    top: 68px;
    bottom: 0;
    overflow-y: scroll;
    width: inherit;
}

.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item {
    border-bottom: 1px solid #021933;
}

.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item>.nav-link {
    position: relative;
    padding: 12px 25px;
}

.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item>.nav-link i:before {
    color: #ffa901;
    font-size: 18px;
    margin-right: 15px;
    margin-left: 0;
}

.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item>.nav-link span {
    font-size: 15px;
    color: #9ea8b5;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item .nav-link.menu-active {
    background-color: #051f3e;
}

.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item .nav-link.menu-active span {
    color: #ffa901;
}

.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item .sub-group-menu {
    display: none;
    background-color: #051f3e;
    padding: 10px 0;
}

.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item .sub-group-menu>.nav-item .nav-link {
    font-size: 14px;
    color: #a3aab3;
    padding: 8px 20px 8px 60px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item .sub-group-menu>.nav-item .nav-link i {
    margin-right: 10px;
}

.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item .sub-group-menu>.nav-item .nav-link:hover {
    background-color: #042954;
    color: #ffffff;
}

.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item .sub-group-menu>.nav-item .nav-link.menu-active {
    background-color: #042954;
    color: #ffa901;
}

.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item .sub-group-menu.sub-group-active {
    display: block;
}

.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu .sidebar-nav-item>.nav-link:after {
    content: "\f107";
    font-family: Flaticon;
    font-size: 10px;
    font-weight: 700;
    color: #b0b8c2;
    position: absolute;
    right: 22px;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(0deg);
    -ms-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu .nav-item.active .nav-link span {
    color: #ffffff;
}

.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu .nav-item.active .nav-link:after {
    color: #ffab01;
    -webkit-transform: translateY(-50%) rotate(90deg);
    -ms-transform: translateY(-50%) rotate(90deg);
    transform: translateY(-50%) rotate(90deg);
}

.sidebar-menu-one .mobile-sidebar-header {
    border-bottom: 1px solid #021933;
}

.sidebar-menu-one .mobile-sidebar-header .header-logo {
    text-align: center;
}

.sidebar-menu-one .mobile-sidebar-header .header-logo a {
    display: block;
    padding: 18px;
}

.sidebar-menu-two {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 18rem;
    background-color: #042954;
    box-sizing: content-box;
    z-index: 10;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.sidebar-menu-two .sidebar-menu-content {
    border: none;
    border-radius: 0;
    background-color: transparent;
}

.sidebar-menu-two .sidebar-menu-content .nav-sidebar-menu {
    flex-direction: column;
}

.sidebar-menu-two .sidebar-menu-content .nav-sidebar-menu>.nav-item {
    border-bottom: 1px solid #021933;
    position: relative;
}

.sidebar-menu-two .sidebar-menu-content .nav-sidebar-menu>.nav-item>.nav-link {
    position: relative;
    padding: 12px 25px;
    text-align: center;
}

.sidebar-menu-two .sidebar-menu-content .nav-sidebar-menu>.nav-item>.nav-link i:before {
    color: #fea801;
    font-size: 24px;
    margin-left: 0;
}

.sidebar-menu-two .sidebar-menu-content .nav-sidebar-menu>.nav-item>.nav-link span {
    display: block;
    font-size: 14px;
    color: #9ea8b5;
}

.sidebar-menu-two .sidebar-menu-content .nav-sidebar-menu>.nav-item .sub-group-menu {
    width: 26rem;
    display: none;
    background-color: #051f3e;
    padding: 12px 0;
    border-radius: 0px 4px 4px 0px;
    border-left: .4px solid #f0f1f3;
    position: absolute;
    top: 0;
    right: -26rem;
    z-index: 10;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.sidebar-menu-two .sidebar-menu-content .nav-sidebar-menu>.nav-item .sub-group-menu>.nav-item .nav-link {
    font-size: 14px;
    color: #a3aab3;
    padding: 10px 25px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.sidebar-menu-two .sidebar-menu-content .nav-sidebar-menu>.nav-item .sub-group-menu>.nav-item .nav-link i {
    margin-right: 10px;
}

.sidebar-menu-two .sidebar-menu-content .nav-sidebar-menu>.nav-item .sub-group-menu>.nav-item .nav-link:hover {
    background-color: #042954;
    color: #ffffff;
}

.sidebar-menu-two .sidebar-menu-content .nav-sidebar-menu>.nav-item:hover {
    background-color: #042040;
}

.sidebar-menu-two .sidebar-menu-content .nav-sidebar-menu>.nav-item:hover>.nav-link i:before {
    color: #fea901;
}

.sidebar-menu-two .sidebar-menu-content .nav-sidebar-menu>.nav-item:hover>.nav-link span {
    color: #ffffff;
}

.sidebar-menu-two .sidebar-menu-content .nav-sidebar-menu>.nav-item:hover .sub-group-menu {
    display: block;
}

.sidebar-menu-two .sidebar-menu-content .nav-sidebar-menu .nav-item.active .nav-link span {
    color: #ffffff;
}

.sidebar-main {
    left: -27rem;
}

@media only screen and (min-width: 768px) {
    .sidebar-expand-md {
        position: static;
        overflow: visible;
    }

    .sidebar-menu-one .sidebar-menu-content {
        position: static;
        overflow: visible;
        width: auto;
    }
}

.sidebar-collapsed .nav-bar-header-one {
    min-width: 8rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media only screen and (max-width: 767px) {
    .sidebar-collapsed .nav-bar-header-one {
        min-width: 26rem;
    }
}

.sidebar-collapsed .nav-bar-header-one .header-logo {
    display: none;
}

@media only screen and (max-width: 767px) {
    .sidebar-collapsed .nav-bar-header-one .header-logo {
        display: block;
    }
}

.sidebar-collapsed .nav-bar-header-one .toggle-button .item-link .btn-icon-wrap span:nth-child(1n) {
    top: 7px;
    transform: rotate(135deg);
}

.sidebar-collapsed .nav-bar-header-one .toggle-button .item-link .btn-icon-wrap span:nth-child(2n) {
    right: 40px;
    opacity: 0;
    visibility: hidden;
}

.sidebar-collapsed .nav-bar-header-one .toggle-button .item-link .btn-icon-wrap span:nth-child(3n) {
    top: 7px;
    transform: rotate(-135deg);
}

.sidebar-collapsed .sidebar-menu-one {
    width: 8rem;
}

.sidebar-collapsed .sidebar-menu-one .sidebar-menu-content .sidebar-menu-header .sidebar-logo {
    display: none;
}

.sidebar-collapsed .sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item {
    position: relative;
}

.sidebar-collapsed .sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item>.nav-link {
    padding: 12px 20px;
    text-align: center;
}

.sidebar-collapsed .sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item>.nav-link i:before {
    margin: 0;
}

.sidebar-collapsed .sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item>.nav-link span {
    display: none;
}

.sidebar-collapsed .sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item>.nav-link:after {
    display: none;
}

.sidebar-collapsed .sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item .sub-group-menu {
    width: 26rem;
    display: block !important;
    position: absolute;
    top: 0;
    left: 8rem;
    visibility: hidden;
    opacity: 0;
    border-left: 0.4px solid #cccccc;
    padding-left: 2px;
    z-index: 100;
}

.sidebar-collapsed .sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item .sub-group-menu>.nav-item>.nav-link {
    padding: 10px 25px;
}

.sidebar-collapsed .sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item .sub-group-menu>.nav-item>.nav-link i {
    display: none;
}

.sidebar-collapsed .sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu>.nav-item:hover .sub-group-menu {
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.sidebar-collapsed-mobile .sidebar-main {
    left: 0;
}

/*=======================================================================
6. TEMPLATE STYLE
=========================================================================*/
/*=============== 6.1 Student Page Style ==================*/
.single-info-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media only screen and (max-width: 1199px) {
    .single-info-details {
        display: block;
    }
}

.single-info-details .item-img {
    margin-right: 40px;
}

@media only screen and (max-width: 1199px) {
    .single-info-details .item-img {
        margin: 0 0 40px 0;
        text-align: center;
    }
}

.single-info-details .item-content {
    flex: 1;
}

@media only screen and (max-width: 575px) {
    .single-info-details .item-content .item-header {
        display: block;
    }
}

@media only screen and (max-width: 575px) {
    .single-info-details .item-content .item-header .header-elements {
        margin-bottom: 15px;
    }
}

.single-info-details .item-content .item-header .header-elements ul li {
    display: inline-block;
    margin-right: 5px;
}

.single-info-details .item-content .item-header .header-elements ul li:last-child {
    margin-right: 0;
}

.single-info-details .item-content .item-header .header-elements ul li a {
    display: block;
    background-color: #f0f1f3;
    border-radius: 4px;
    color: #646464;
    font-size: 14px;
    padding: 7px 12px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.single-info-details .item-content .item-header .header-elements ul li a:hover {
    background-color: #ff9d01;
    color: #fff;
}

.single-info-details .item-content p {
    width: 50%;
}

@media only screen and (max-width: 1499px) {
    .single-info-details .item-content p {
        width: 80%;
    }
}

@media only screen and (max-width: 1199px) {
    .single-info-details .item-content p {
        width: 100%;
    }
}

.single-info-details .item-content .info-table .table {
    width: 50%;
}

@media only screen and (max-width: 1499px) {
    .single-info-details .item-content .info-table .table {
        width: 70%;
    }
}

@media only screen and (max-width: 991px) {
    .single-info-details .item-content .info-table .table {
        width: 100%;
    }
}

.single-info-details .item-content .info-table .table tr td {
    border: none;
    padding: 10px;
}

.new-added-form .form-group {
    position: relative;
    margin-bottom: 2.3rem;
}

.new-added-form .form-group:last-child {
    margin-bottom: 0;
}

.new-added-form .form-group label {
    margin-bottom: 12px;
    display: block;
}

.new-added-form .form-group .form-control {
    background-color: #f0f1f3;
    height: 50px;
}

.new-added-form .form-group i {
    position: absolute;
    top: 56px;
    right: 34px;
    font-size: 15px;
    color: #000;
}

.new-added-form .form-group .textarea {
    height: auto;
}

.new-added-form .form-group .form-control-file {
    display: inline-block;
    width: auto;
}

.new-added-form .form-group .form-control-file:focus {
    outline: none;
}

.new-added-form .form-group .btn-fill-lg {
    margin-right: 10px;
    margin-bottom: 10px;
}

.new-added-form .form-group .btn-fill-lg:last-child {
    margin-right: 0;
    margin-bottom: 0;
}

/*=============== 6.2 Notice Board ==================*/
.notice-board-wrap {
    /* max-height: 900px;
    overflow-y: scroll; */
    padding-right: 20px;
}

.notice-board-wrap .notice-list {
    border-bottom: 1px solid #ededed;
    padding-bottom: 14px;
    margin-bottom: 18px;
}

.notice-board-wrap .notice-list .post-date {
    display: inline-block;
    font-size: 14px;
    color: #ffffff;
    padding: 5px 14px;
    border-radius: 20px;
    margin-bottom: 14px;
}

.notice-board-wrap .notice-list .notice-title {
    font-weight: 500;
    margin-bottom: 8px;
}

.notice-board-wrap .notice-list .notice-title a {
    color: #111111;
}

.notice-board-wrap .notice-list .entry-meta {
    font-size: 15px;
    color: #646464;
}

.notice-board-wrap .notice-list .entry-meta span {
    color: #a4a4a4;
}

/*=============== 6.3 Messaging ==================*/
.message-box-wrap .card-body .message-success-box {
    text-align: center;
    padding: 5rem 2rem;
}

@media only screen and (max-width: 1399px) {
    .message-box-wrap .card-body .message-success-box {
        padding: 5rem 1rem;
    }
}

.message-box-wrap .card-body .message-success-box .item-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1199px) {
    .message-box-wrap .card-body .message-success-box .item-content {
        display: block;
    }
}

.message-box-wrap .card-body .message-success-box .item-content .item-title {
    font-size: 16px;
    color: #646464;
    font-style: italic;
    margin-bottom: 0;
    flex: 1;
}

.message-box-wrap .card-body .message-success-box .item-content .item-icon {
    margin-right: 45px;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    color: #ffffff;
    height: 44px;
    width: 44px;
    line-height: 44px;
    border-radius: 50%;
    background-color: #92c755;
    position: relative;
}

@media only screen and (max-width: 1199px) {
    .message-box-wrap .card-body .message-success-box .item-content .item-icon {
        margin: 0 0 35px 0;
    }
}

.message-box-wrap .card-body .message-success-box .item-content .item-icon:before {
    content: "";
    height: 58px;
    width: 58px;
    border: 8px solid rgba(146, 199, 85, 0.7);
    border-radius: 50%;
    position: absolute;
    top: -7px;
    left: -7px;
}

.message-box-wrap .card-body .message-success-box .item-content .item-icon:after {
    content: "";
    height: 78px;
    width: 78px;
    border: 10px solid rgba(146, 199, 85, 0.3);
    border-radius: 50%;
    position: absolute;
    top: -17px;
    left: -17px;
}

.message-box-wrap .card-body .message-error-box {
    text-align: center;
    padding: 5rem 2rem;
}

@media only screen and (max-width: 1399px) {
    .message-box-wrap .card-body .message-error-box {
        padding: 5rem 1rem;
    }
}

.message-box-wrap .card-body .message-error-box .item-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1199px) {
    .message-box-wrap .card-body .message-error-box .item-content {
        display: block;
    }
}

.message-box-wrap .card-body .message-error-box .item-content .item-title {
    font-size: 16px;
    color: #646464;
    font-style: italic;
    margin-bottom: 0;
    flex: 1;
}

.message-box-wrap .card-body .message-error-box .item-content .item-icon {
    margin-right: 45px;
    text-align: center;
    display: inline-block;
    font-size: 18px;
    color: #ffffff;
    height: 44px;
    width: 44px;
    line-height: 44px;
    border-radius: 50%;
    background-color: #ee0d0d;
    position: relative;
}

@media only screen and (max-width: 1199px) {
    .message-box-wrap .card-body .message-error-box .item-content .item-icon {
        margin: 0 0 35px 0;
    }
}

.message-box-wrap .card-body .message-error-box .item-content .item-icon:before {
    content: "";
    height: 58px;
    width: 58px;
    border: 8px solid rgba(238, 13, 13, 0.5);
    border-radius: 50%;
    position: absolute;
    top: -7px;
    left: -7px;
}

.message-box-wrap .card-body .message-error-box .item-content .item-icon:after {
    content: "";
    height: 78px;
    width: 78px;
    border: 10px solid rgba(238, 13, 13, 0.3);
    border-radius: 50%;
    position: absolute;
    top: -17px;
    left: -17px;
}

/*=============== 6.4 Account Settings ==================*/
.account-settings-box .user-details-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media only screen and (max-width: 1499px) {
    .account-settings-box .user-details-box {
        display: block;
    }
}

.account-settings-box .user-details-box .item-img {
    margin-right: 40px;
}

@media only screen and (max-width: 1499px) {
    .account-settings-box .user-details-box .item-img {
        margin: 0 0 40px 0;
    }
}

@media only screen and (max-width: 575px) {
    .account-settings-box .user-details-box .item-img {
        text-align: center;
    }
}

.account-settings-box .user-details-box .item-img img {
    border-radius: 50%;
}

.account-settings-box .user-details-box .item-content {
    flex: 1;
}

.account-settings-box .user-details-box .item-content .info-table .table {
    width: 100%;
}

.account-settings-box .user-details-box .item-content .info-table .table tr td {
    border: none;
    padding: 15px 10px;
    font-size: 16px;
}

.account-settings-box .all-user-box .media {
    align-items: center;
    background-color: #f7f7f7;
    padding: 20px 30px;
    margin-bottom: 2px;
    border-radius: 4px;
    cursor: pointer;
}

@media only screen and (max-width: 575px) {
    .account-settings-box .all-user-box .media {
        text-align: center;
    }
}

.account-settings-box .all-user-box .media .item-img img {
    border-radius: 50%;
}

.account-settings-box .all-user-box .media .media-body .item-title {
    font-weight: 500;
    color: #111111;
    margin-bottom: 5px;
    font-size: 16px;
}

.account-settings-box .all-user-box .media .media-body .item-subtitle {
    font-size: 15px;
}

.account-settings-box .all-user-box .media.active {
    background-color: #debbbb;
}

/*=============== 6.5 UI Elements ==================*/
.ui-btn-wrap ul li {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 14px;
}

.ui-btn-wrap ul li:last-child {
    margin-right: 0;
}

.ui-grid-box {
    background-color: #f2f2f2;
    padding: 12px 25px;
    margin-bottom: 30px;
}

.progress-bar-card {
    height: auto;
}

.progress-bar-card .card-body .basic-progress-bar .progress {
    margin-bottom: 30px;
    height: 7px;
}

.progress-bar-card .card-body .stripe-progress-bar .progress {
    height: 20px;
    margin-bottom: 20px;
}

.progress-bar-card .card-body .gradient-progress-bar .progress {
    height: 30px;
    margin-bottom: 20px;
}

.progress-bar-card .card-body .gradient-progress-bar .progress .progress-bar {
    text-align: right;
    padding-right: 10px;
    font-size: 18px;
}

.progress-bar-card .card-body .vertical-progress-bar .progress-bar-vertical {
    width: 70px;
    min-height: 200px;
    display: flex;
    align-items: flex-end;
    margin-right: 20px;
    margin-bottom: 20px;
    float: left;
}

.progress-bar-card .card-body .vertical-progress-bar .progress-bar-vertical .progress-bar {
    width: 100%;
    height: 0;
    -webkit-transition: height 0.6s ease;
    -o-transition: height 0.6s ease;
    transition: height 0.6s ease;
    font-size: 18px;
    font-weight: 500;
}

.ui-tab-card {
    height: auto;
}

.ui-tab-card .card-body .basic-tab .nav-tabs .nav-item .nav-link {
    color: #646464;
    padding: 10px 50px;
}

@media only screen and (max-width: 991px) {
    .ui-tab-card .card-body .basic-tab .nav-tabs .nav-item .nav-link {
        padding: 10px 30px;
    }
}

@media only screen and (max-width: 575px) {
    .ui-tab-card .card-body .basic-tab .nav-tabs .nav-item .nav-link {
        padding: 10px;
    }
}

.ui-tab-card .card-body .basic-tab .nav-tabs .nav-item .nav-link.active {
    color: #3d5afe;
}

.ui-tab-card .card-body .basic-tab .tab-content .tab-pane {
    border: 1px solid #dee2e6;
    border-top: none;
    padding: 25px 30px 5px;
}

.ui-tab-card .card-body .custom-tab .nav-tabs {
    border-bottom: 4px solid #dee2e6;
}

.ui-tab-card .card-body .custom-tab .nav-tabs .nav-item .nav-link {
    margin-right: 20px;
    padding-bottom: 8px;
    border: none;
    color: #646464;
    position: relative;
}

.ui-tab-card .card-body .custom-tab .nav-tabs .nav-item .nav-link:after {
    content: "";
    height: 4px;
    width: 0;
    background-color: #80deea;
    position: absolute;
    bottom: -3px;
    left: 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.ui-tab-card .card-body .custom-tab .nav-tabs .nav-item .nav-link:hover:after {
    width: 100%;
}

.ui-tab-card .card-body .custom-tab .nav-tabs .nav-item .nav-link.active {
    background-color: transparent;
    color: #80deea;
}

.ui-tab-card .card-body .custom-tab .nav-tabs .nav-item .nav-link.active:after {
    width: 100%;
}

.ui-tab-card .card-body .custom-tab .tab-content .tab-pane {
    padding-top: 25px;
}

.ui-tab-card .card-body .border-nav-tab .nav-tabs {
    border: 1px solid #dee2e6;
}

.ui-tab-card .card-body .border-nav-tab .nav-tabs .nav-item .nav-link {
    color: #646464;
    border-top: none;
    border-left: none;
    border-right: 1px solid;
    border-color: #dee2e6;
    border-radius: 0;
    padding: 10px 30px;
}

.ui-tab-card .card-body .border-nav-tab .nav-tabs .nav-item .nav-link:hover {
    background-color: #f57c00;
    border-color: #f57c00;
    color: #ffffff;
    box-shadow: 0px 5px 13px 0px rgba(245, 124, 0, 0.4);
}

.ui-tab-card .card-body .border-nav-tab .nav-tabs .nav-item .nav-link.active {
    background-color: #f57c00;
    color: #ffffff;
    box-shadow: 0px 5px 13px 0px rgba(245, 124, 0, 0.4);
    border-color: #f57c00;
}

.ui-tab-card .card-body .border-nav-tab .tab-content {
    padding-top: 30px;
}

.ui-tab-card .card-body .vertical-tab {
    display: flex;
}

@media only screen and (max-width: 767px) {
    .ui-tab-card .card-body .vertical-tab {
        display: block;
    }
}

.ui-tab-card .card-body .vertical-tab .nav-tabs {
    min-width: 140px;
    margin-right: 30px;
    border-bottom: none;
}

@media only screen and (max-width: 767px) {
    .ui-tab-card .card-body .vertical-tab .nav-tabs {
        margin-bottom: 30px;
    }
}

.ui-tab-card .card-body .vertical-tab .nav-tabs .nav-item .nav-link {
    color: #646464;
    border: 1px solid;
    border-color: #dee2e6;
    border-radius: 4px;
    padding: 10px 30px;
    position: relative;
    height: 100%;
}

.ui-tab-card .card-body .vertical-tab .nav-tabs .nav-item .nav-link:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid #ff1744;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    visibility: hidden;
    opacity: 0;
}

.ui-tab-card .card-body .vertical-tab .nav-tabs .nav-item .nav-link:hover {
    background-color: #ff1744;
    border-color: #ff1744;
    color: #ffffff;
}

.ui-tab-card .card-body .vertical-tab .nav-tabs .nav-item .nav-link:hover:after {
    visibility: visible;
    opacity: 1;
    right: -10px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.ui-tab-card .card-body .vertical-tab .nav-tabs .nav-item .nav-link.active {
    background-color: #ff1744;
    color: #ffffff;
    border-color: #ff1744;
}

.ui-tab-card .card-body .vertical-tab .nav-tabs .nav-item .nav-link.active:after {
    visibility: visible;
    opacity: 1;
    right: -10px;
}

.ui-tab-card .card-body .icon-tab .nav-tabs {
    border: none;
}

.ui-tab-card .card-body .icon-tab .nav-tabs .nav-item .nav-link {
    margin-right: 6px;
    padding-bottom: 8px;
    color: #646464;
    border-radius: 4px;
    padding: 10px 20px;
}

.ui-tab-card .card-body .icon-tab .nav-tabs .nav-item .nav-link:hover {
    background-color: #7cc576 !important;
    border-color: #7cc576 !important;
}

.ui-tab-card .card-body .icon-tab .nav-tabs .nav-item .nav-link:hover i {
    color: #ffffff !important;
}

.ui-tab-card .card-body .icon-tab .nav-tabs .nav-item .nav-link.active {
    background-color: #7cc576 !important;
    border-color: #7cc576 !important;
}

.ui-tab-card .card-body .icon-tab .nav-tabs .nav-item .nav-link.active i {
    color: #ffffff !important;
}

.ui-tab-card .card-body .icon-tab .tab-content {
    padding-top: 26px;
}

.ui-alart-box .dismiss-alart .alert-dismissible .close {
    top: 50%;
    transform: translateY(-50%);
}

.ui-alart-box .dismiss-alart .alert-dismissible .close span {
    font-size: 24px;
    color: #7c7c7c;
}

.ui-alart-box .icon-color-alart .icon-alart {
    color: #ffffff;
    padding-left: 75px;
    position: relative;
    border: none;
}

.ui-alart-box .icon-color-alart .icon-alart i {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 3px 0 0 3px;
    font-size: 16px;
    height: 99%;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ui-modal-box .modal-box {
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 25px;
    margin-bottom: 10px;
}

.ui-modal-box .modal-box:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.ui-modal-box .modal-box label {
    display: block;
    margin-bottom: 10px;
}

.ui-modal-box .modal-box .modal .success-modal-content .modal-content .modal-body {
    padding: 40px;
}

@media only screen and (max-width: 479px) {
    .ui-modal-box .modal-box .modal .success-modal-content .modal-content .modal-body {
        text-align: center;
    }
}

.ui-modal-box .modal-box .modal .success-modal-content .modal-content .modal-body .success-message {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 479px) {
    .ui-modal-box .modal-box .modal .success-modal-content .modal-content .modal-body .success-message {
        display: block;
    }
}

.ui-modal-box .modal-box .modal .success-modal-content .modal-content .modal-body .success-message .item-title {
    font-size: 16px;
    color: #646464;
    font-style: italic;
    margin-bottom: 0;
    flex: 1;
}

.ui-modal-box .modal-box .modal .success-modal-content .modal-content .modal-body .success-message .item-icon {
    margin-right: 45px;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    color: #ffffff;
    height: 44px;
    width: 44px;
    line-height: 44px;
    border-radius: 50%;
    background-color: #92c755;
    position: relative;
}

@media only screen and (max-width: 479px) {
    .ui-modal-box .modal-box .modal .success-modal-content .modal-content .modal-body .success-message .item-icon {
        margin: 0 0 35px 0;
    }
}

.ui-modal-box .modal-box .modal .success-modal-content .modal-content .modal-body .success-message .item-icon:before {
    content: "";
    height: 58px;
    width: 58px;
    border: 8px solid rgba(146, 199, 85, 0.7);
    border-radius: 50%;
    position: absolute;
    top: -7px;
    left: -7px;
}

.ui-modal-box .modal-box .modal .success-modal-content .modal-content .modal-body .success-message .item-icon:after {
    content: "";
    height: 78px;
    width: 78px;
    border: 10px solid rgba(146, 199, 85, 0.3);
    border-radius: 50%;
    position: absolute;
    top: -17px;
    left: -17px;
}

.ui-modal-box .modal-box .left-slide-modal .modal-dialog {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}

.ui-modal-box .modal-box .left-slide-modal .modal-dialog .modal-content {
    height: 100%;
}

.ui-modal-box .modal-box .left-slide-modal.show .modal-dialog {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.ui-modal-box .modal-box .right-slide-modal .modal-dialog {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}

.ui-modal-box .modal-box .right-slide-modal .modal-dialog .modal-content {
    height: 100%;
}

.ui-modal-box .modal-box .right-slide-modal.show .modal-dialog {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body {
    position: relative;
    padding: 50px 50px 30px;
}

@media only screen and (max-width: 479px) {
    .ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body {
        padding: 50px 20px 30px;
    }
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .close-btn {
    border-bottom: none;
    position: absolute;
    top: 10px;
    right: 10px;
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .close-btn .close {
    font-size: 24px;
    height: 40px;
    width: 40px;
    background-color: #f8f8f8;
    border-radius: 50%;
    opacity: 1;
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .close-btn .close:focus {
    outline: none;
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .close-btn .close span {
    text-shadow: none;
    color: #444444;
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .item-logo {
    text-align: center;
    margin-bottom: 40px;
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .login-form .form-group {
    position: relative;
    margin-bottom: 15px;
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .login-form .form-group .form-control {
    background-color: transparent;
    border: 1px solid #c2c2c2;
    padding: 5px 35px 5px 20px;
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .login-form .form-group>input {
    height: 50px;
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .login-form .form-group i {
    position: absolute;
    top: 60%;
    right: 18px;
    font-size: 15px;
    color: #9f9f9f;
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .login-form .form-group input.form-control::-webkit-input-placeholder,
.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .login-form .form-group textarea.form-control::-webkit-input-placeholder {
    color: #cccccc;
    font-size: 15px;
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .login-form .form-group input.form-control::-moz-placeholder,
.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .login-form .form-group textarea.form-control::-moz-placeholder {
    color: #cccccc;
    font-size: 15px;
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .login-form .form-group input.form-control:-ms-input-placeholder,
.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .login-form .form-group textarea.form-control:-ms-input-placeholder {
    color: #cccccc;
    font-size: 15px;
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .login-form .form-group .form-check .form-check-label {
    cursor: pointer;
    font-size: 14px;
    padding-left: 30px;
    font-weight: 500;
    color: #444444;
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .login-form .form-group .form-check .form-check-label:before {
    top: 1px;
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .login-form .form-group .form-check .form-check-label:after {
    top: 2px;
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .login-form .form-group .login-btn {
    cursor: pointer;
    width: 100%;
    border: none;
    position: relative;
    text-align: center;
    display: block;
    font-weight: 700;
    color: #ffffff;
    background: -webkit-gradient(linear, left bottom, left top, from(#042954), to(#0b4776));
    background: -o-linear-gradient(bottom, #042954, #0b4776);
    background: linear-gradient(to top, #042954, #0b4776);
    border-radius: 4px;
    padding: 16px 10px;
    z-index: 1;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .login-form .form-group .login-btn:after {
    content: "";
    height: 100%;
    width: 100%;
    background: linear-gradient(to top, #0b4776, #042954);
    border-radius: 4px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.ui-modal-box .modal-box .sign-up-modal .modal-dialog .modal-content .modal-body .login-form .form-group .login-btn:hover:after {
    visibility: visible;
    opacity: 1;
}

.ui-modal-box .modal-box .notification-modal .modal-dialog {
    position: absolute;
    bottom: 20px;
    right: 0;
    margin: 50px;
    max-width: 300px;
}

.ui-modal-box .modal-box .notification-modal .modal-dialog .modal-content {
    background-color: #042954;
}

.ui-modal-box .modal-box .notification-modal .modal-dialog .modal-content .modal-body {
    text-align: center;
    padding: 50px 20px 20px;
}

.ui-modal-box .modal-box .notification-modal .modal-dialog .modal-content .modal-body .item-title {
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 10px;
}

.ui-modal-box .modal-box .notification-modal .modal-dialog .modal-content .modal-body p {
    color: #dedede;
}

.ui-modal-box .modal-box .notification-modal .modal-dialog .modal-content .modal-body .close-btn button {
    cursor: pointer;
    display: block;
    margin: 0 auto 15px;
}

.ui-modal-box .modal-box .notification-modal .modal-dialog .modal-content .modal-body .close-btn button:focus {
    outline: none;
}

.ui-modal-box .modal-box .notification-modal .modal-dialog .modal-content .modal-body .close-btn .item-btn {
    background-color: #ffffff;
    border-radius: 4px;
    border: none;
    color: #111111;
    font-weight: 500;
    width: 100%;
    padding: 8px 10px;
}

.ui-modal-box .modal-box .notification-modal .modal-dialog .modal-content .modal-body .close-btn .close-btn {
    background: none;
    border: none;
    color: #ffffff;
}

.ui-modal-box .modal-box .feedback-modal .modal-dialog {
    position: absolute;
    bottom: 20px;
    right: 0;
    margin: 50px;
    max-width: 300px;
}

@media only screen and (max-width: 767px) {
    .ui-modal-box .modal-box .feedback-modal .modal-dialog {
        top: 0;
    }
}

.ui-modal-box .modal-box .feedback-modal .modal-dialog .modal-content .modal-body {
    text-align: center;
    padding: 50px 20px 20px;
}

.ui-modal-box .modal-box .feedback-modal .modal-dialog .modal-content .modal-body .close-btn {
    border-bottom: none;
    position: absolute;
    top: 15px;
    right: 20px;
}

.ui-modal-box .modal-box .feedback-modal .modal-dialog .modal-content .modal-body .close-btn .close {
    font-size: 24px;
    height: 40px;
    width: 40px;
    background-color: #f8f8f8;
    border-radius: 50%;
    opacity: 1;
}

.ui-modal-box .modal-box .feedback-modal .modal-dialog .modal-content .modal-body .close-btn .close:focus {
    outline: none;
}

.ui-modal-box .modal-box .feedback-modal .modal-dialog .modal-content .modal-body .close-btn .close span {
    text-shadow: none;
    color: #444444;
}

.ui-modal-box .modal-box .feedback-modal .modal-dialog .modal-content .modal-body .item-title {
    font-size: 24px;
    font-weight: 500;
    color: #111111;
    margin-bottom: 10px;
}

.ui-modal-box .modal-box .feedback-modal .modal-dialog .modal-content .modal-body .feedback-form .form-group {
    text-align: left;
}

.ui-modal-box .modal-box .feedback-modal .modal-dialog .modal-content .modal-body .feedback-form .form-group .form-check label {
    padding-left: 30px;
    font-weight: 500;
    color: #444444;
}

.ui-modal-box .modal-box .feedback-modal .modal-dialog .modal-content .modal-body .feedback-form .form-group .item-btn {
    margin-top: 20px;
    background-color: #042954;
    border-radius: 4px;
    border: none;
    color: #ffffff;
    font-weight: 500;
    width: 100%;
    padding: 8px 10px;
    cursor: pointer;
    display: block;
    margin: 0 auto 15px;
}

.ui-modal-box .modal-box .feedback-modal .modal-dialog .modal-content .modal-body .feedback-form .form-group .item-btn:focus {
    outline: none;
}

/*=============== 6.6 Login Page ==================*/
.login-page-wrap {
    /* background-image: url('img/figure/login-bg.jpg'); */
    background-repeat: no-repeat;
    background-position: top;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 1024px) {
    .login-page-wrap {
        position: relative;
        height: auto;
    }
}

.login-page-content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px;
}

@media only screen and (max-width: 575px) {
    .login-page-content {
        padding: 50px 20px;
    }
}

.login-page-content .login-box {
    min-width: 470px;
    padding: 6rem 7rem;
    border-radius: 4px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 5px 49px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 5px 49px 0px rgba(0, 0, 0, 0.5);
    margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
    .login-page-content .login-box {
        min-width: 300px;
        padding: 6rem 4rem;
    }
}

@media only screen and (max-width: 479px) {
    .login-page-content .login-box {
        padding: 6rem 2rem;
    }
}

.login-page-content .login-box .item-logo {
    text-align: center;
    margin-bottom: 40px;
}

.login-page-content .login-box .login-form .form-group {
    position: relative;
    margin-bottom: 18px;
}

.login-page-content .login-box .login-form .form-group>label {
    font-size: 15px;
    font-weight: 500;
    color: #444444;
    margin-bottom: 10px;
}

.login-page-content .login-box .login-form .form-group .form-control {
    background-color: transparent;
    border: 1px solid #c2c2c2;
    padding: 5px 35px 5px 20px;
}

.login-page-content .login-box .login-form .form-group>input {
    height: 50px;
}

.login-page-content .login-box .login-form .form-group i {
    position: absolute;
    top: 60%;
    right: 18px;
    font-size: 15px;
    color: #9f9f9f;
}

.login-page-content .login-box .login-form .form-group input.form-control::-webkit-input-placeholder,
.login-page-content .login-box .login-form .form-group textarea.form-control::-webkit-input-placeholder {
    color: #adadad;
    font-size: 15px;
}

.login-page-content .login-box .login-form .form-group input.form-control::-moz-placeholder,
.login-page-content .login-box .login-form .form-group textarea.form-control::-moz-placeholder {
    color: #adadad;
    font-size: 15px;
}

.login-page-content .login-box .login-form .form-group input.form-control:-ms-input-placeholder,
.login-page-content .login-box .login-form .form-group textarea.form-control:-ms-input-placeholder {
    color: #adadad;
    font-size: 15px;
}

.login-page-content .login-box .login-form .form-group .form-check .form-check-label {
    cursor: pointer;
    font-size: 14px;
    padding-left: 30px;
    font-weight: 500;
    color: #444444;
}

.login-page-content .login-box .login-form .form-group .form-check .form-check-label:before {
    top: 1px;
}

.login-page-content .login-box .login-form .form-group .form-check .form-check-label:after {
    top: 2px;
}

.login-page-content .login-box .login-form .form-group .forgot-btn {
    font-size: 14px;
    color: #ff0000;
    font-weight: 500;
}

.login-page-content .login-box .login-form .form-group .login-btn {
    cursor: pointer;
    width: 100%;
    border: none;
    margin-top: 30px;
    position: relative;
    text-align: center;
    display: block;
    font-weight: 700;
    color: #ffffff;
    background: -webkit-gradient(linear, left bottom, left top, from(#042954), to(#0b4776));
    background: -o-linear-gradient(bottom, #042954, #0b4776);
    background: linear-gradient(to top, #042954, #0b4776);
    border-radius: 4px;
    padding: 16px 10px;
    z-index: 1;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.login-page-content .login-box .login-form .form-group .login-btn:after {
    content: "";
    height: 100%;
    width: 100%;
    background: linear-gradient(to top, #0b4776, #042954);
    border-radius: 4px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.login-page-content .login-box .login-form .form-group .login-btn:hover:after {
    visibility: visible;
    opacity: 1;
}

.login-page-content .login-box .login-social {
    text-align: center;
}

.login-page-content .login-box .login-social p {
    margin-bottom: 0;
}

.login-page-content .login-box .login-social ul {
    margin-top: 10px;
}

.login-page-content .login-box .login-social ul li {
    display: inline-block;
    margin-right: 2px;
}

.login-page-content .login-box .login-social ul li:last-child {
    margin-right: 0;
}

.login-page-content .login-box .login-social ul li a {
    width: 45px;
    height: 42px;
    line-height: 42px;
    display: inline-block;
    border-radius: 4px;
    color: #ffffff;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.login-page-content .login-box .login-social ul li a:hover {
    background-color: #ffaa01;
}

.login-page-content .sign-up {
    color: #ffffff;
}

.login-page-content .sign-up a {
    color: #ffffff;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.login-page-content .sign-up a:hover {
    color: #ffaa01;
}

/*=======================================================================
7. DASHBOARD STYLES 
=========================================================================*/
/*=============== 7.1 Dashboard Page One ==================*/
.dashboard-page-one {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.dashboard-content-one {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    overflow: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 30px;
}

@media only screen and (max-width: 767px) {
    .dashboard-content-one {
        padding: 0 15px;
    }
}

.dashboard-summery-one {
    margin-bottom: 30px;
    background-color: #ffffff;
    padding: 22px 28px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px 0px rgba(229, 229, 229, 0.75);
}

.dashboard-summery-one .item-icon {
    position: relative;
    height: 85px;
    width: 85px;
    line-height: 85px;
    border-radius: 50%;
    text-align: center;
}

.dashboard-summery-one .item-icon i:before {
    margin-left: 0;
    font-size: 44px;
}

.dashboard-summery-one .item-content {
    text-align: right;
}

.dashboard-summery-one .item-content .item-title {
    color: #a8a8a8;
    margin-bottom: 2px;
}

.dashboard-summery-one .item-content .item-number {
    font-size: 20px;
    font-weight: 500;
    color: #111111;
}

@media only screen and (max-width: 991px) {
    .dashboard-summery-one .item-content .item-number {
        font-size: 20px;
    }
}

.dashboard-card-one .card-body .earning-report {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 28px;
}

@media only screen and (max-width: 991px) {
    .dashboard-card-one .card-body .earning-report {
        margin-bottom: 20px;
        display: block;
    }
}

.dashboard-card-one .card-body .earning-report .item-content .single-item {
    display: inline-block;
    margin-right: 40px;
    padding-left: 34px;
    position: relative;
}

@media only screen and (max-width: 991px) {
    .dashboard-card-one .card-body .earning-report .item-content .single-item {
        margin-bottom: 20px;
    }
}

.dashboard-card-one .card-body .earning-report .item-content .single-item:after {
    content: "";
    height: 18px;
    width: 18px;
    border: 3px solid #ffffff;
    -webkit-box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
}

.dashboard-card-one .card-body .earning-report .item-content .single-item:last-child {
    margin-right: 0;
}

.dashboard-card-one .card-body .earning-report .item-content .single-item h4 {
    color: #9f9f9f;
    margin-bottom: 4px;
    font-size: 16px;
}

.dashboard-card-one .card-body .earning-report .item-content .single-item span {
    padding-left: 13px;
    font-size: 22px;
    font-weight: 500;
    color: #222222;
    position: relative;
    display: inline-block;
}

@media only screen and (max-width: 991px) {
    .dashboard-card-one .card-body .earning-report .item-content .single-item span {
        font-size: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .dashboard-card-one .card-body .earning-report .item-content .single-item span {
        font-size: 18px;
    }
}

.dashboard-card-one .card-body .earning-report .item-content .single-item span:after {
    content: "$";
    font-size: 16px;
    color: #646464;
    font-weight: 500;
    position: absolute;
    top: 3px;
    left: 0;
}

.dashboard-card-one .card-body .earning-report .dropdown {
    text-align: right;
}

@media only screen and (max-width: 991px) {
    .dashboard-card-one .card-body .earning-report .dropdown {
        display: none;
    }
}

.dashboard-card-one .card-body .earning-report .dropdown .date-dropdown-toggle {
    color: #a7a7a7;
    position: relative;
    padding-right: 24px;
}

.dashboard-card-one .card-body .earning-report .dropdown .date-dropdown-toggle:after {
    content: "\f108";
    font-family: Flaticon;
    position: absolute;
    font-size: 12px;
    font-weight: 600;
    right: 2px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 5;
    color: #222222;
}

.dashboard-card-one .card-body .earning-report .dropdown .dropdown-menu {
    top: 20px !important;
    min-width: 15rem;
    animation: none;
}

.dashboard-card-one .card-body .earning-report .dropdown .dropdown-menu .dropdown-item {
    font-size: 14px;
    padding: 10px 15px;
}

.dashboard-card-one .card-body .earning-chart-wrap {
    position: relative;
}

.dashboard-card-two .card-body .expense-report {
    display: flex;
    margin-bottom: 38px;
}

.dashboard-card-two .card-body .expense-report .monthly-expense {
    position: relative;
    margin-right: 40px;
}

@media only screen and (max-width: 1699px) {
    .dashboard-card-two .card-body .expense-report .monthly-expense {
        margin-right: 13px;
    }
}

@media only screen and (max-width: 1499px) {
    .dashboard-card-two .card-body .expense-report .monthly-expense {
        margin-right: 30px;
    }
}

@media only screen and (max-width: 1299px) {
    .dashboard-card-two .card-body .expense-report .monthly-expense {
        margin-right: 13px;
    }
}

@media only screen and (max-width: 1199px) {
    .dashboard-card-two .card-body .expense-report .monthly-expense {
        margin-right: 40px;
    }
}

@media only screen and (max-width: 479px) {
    .dashboard-card-two .card-body .expense-report .monthly-expense {
        margin-right: 15px;
    }
}

.dashboard-card-two .card-body .expense-report .monthly-expense:last-child {
    margin-right: 0;
}

.dashboard-card-two .card-body .expense-report .monthly-expense:after {
    content: "";
    height: 4px;
    width: 100%;
    border-radius: 6px;
    position: absolute;
    bottom: -7px;
    left: 0;
    right: 0;
}

.dashboard-card-two .card-body .expense-report .monthly-expense .expense-date {
    font-size: 15px;
    color: #9f9f9f;
}

.dashboard-card-two .card-body .expense-report .monthly-expense .expense-amount {
    color: #222222;
    font-weight: 600;
    font-size: 16px;
}

.dashboard-card-two .card-body .expense-report .monthly-expense .expense-amount span {
    font-size: 16px;
    color: #646464;
}

.dashboard-card-three .card-body .doughnut-chart-wrap {
    padding: 5px 0 50px;
    display: flex;
    justify-content: center;
}

.dashboard-card-three .card-body .student-report {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.dashboard-card-three .card-body .student-report:before {
    content: "";
    height: 58px;
    width: 1px;
    background-color: #d7d7d7;
    position: absolute;
    top: -15px;
    right: 50%;
    transform: translateX(-50%);
}

.dashboard-card-three .card-body .student-report .student-count {
    position: relative;
}

.dashboard-card-three .card-body .student-report .student-count .item-title {
    font-size: 14px;
    color: #a5a5a5;
    margin-bottom: 2px;
}

.dashboard-card-three .card-body .student-report .student-count .item-number {
    font-size: 16px;
    color: #111111;
    font-weight: 500;
}

.dashboard-card-three .card-body .student-report .student-count:after {
    content: "";
    height: 6px;
    width: 49px;
    border-radius: 6px;
    position: absolute;
    top: -18px;
    left: 0;
}

.dashboard-card-three .card-body .student-report .student-count:last-child:before {
    display: none;
}

.dashboard-card-four .card-body .calender-wrap .fc-calender .fc-view-container .fc-basicWeek-view table .fc-head .fc-head-container .fc-day-header {
    font-size: 14px;
}

.dashboard-card-five .card-body .traffic-title {
    color: #a5a5a5;
    margin-bottom: 10px;
}

.dashboard-card-five .card-body .traffic-number {
    font-size: 30px;
    font-weight: 700;
    color: #222222;
    line-height: 1;
    margin-bottom: 25px;
}

.dashboard-card-five .card-body .traffic-bar {
    display: flex;
    margin-bottom: 18px;
}

.dashboard-card-five .card-body .traffic-bar .direct {
    flex: 0 0 50%;
    max-width: 50%;
    min-height: 10px;
    background-color: #1de9b6;
    border-radius: 40px 0 0 40px;
}

.dashboard-card-five .card-body .traffic-bar .search {
    flex: 0 0 27%;
    max-width: 27%;
    min-height: 10px;
    background-color: #304ffe;
}

.dashboard-card-five .card-body .traffic-bar .referrals {
    flex: 0 0 16%;
    max-width: 16%;
    min-height: 10px;
    background-color: #ffea00;
}

.dashboard-card-five .card-body .traffic-bar .social {
    flex: 0 0 7%;
    max-width: 7%;
    min-height: 10px;
    background-color: #ed1c24;
    border-radius: 0 40px 40px 0;
}

.dashboard-card-five .card-body .traffic-table .table tbody tr {
    border-bottom: 1px dashed #e1e1e1;
}

.dashboard-card-five .card-body .traffic-table .table tbody tr:last-child {
    border-bottom: none;
}

.dashboard-card-five .card-body .traffic-table .table tbody tr td {
    color: #111111;
    font-weight: 500;
    border-top: none;
    padding: 20px 15px;
}

.dashboard-card-five .card-body .traffic-table .table tbody tr .t-title {
    position: relative;
    padding-left: 34px;
}

.dashboard-card-five .card-body .traffic-table .table tbody tr .t-title:after {
    content: "";
    height: 17px;
    width: 17px;
    border-radius: 50%;
    border: 3px solid #ffffff;
    -webkit-box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
}

.dashboard-card-six ::-webkit-scrollbar {
    width: 10px;
    background: #f0f1f2;
    border-radius: 30px;
}

.dashboard-card-six ::-webkit-scrollbar-track {
    border-radius: 30px;
    background: #f0f1f2;
}

.dashboard-card-six ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: #3751fe;
}

.dashboard-card-six .card-body .notice-box-wrap {
    max-height: 358px;
    overflow-y: scroll;
    padding-right: 20px;
}

.dashboard-card-six .card-body .notice-box-wrap .notice-list {
    border-bottom: 1px solid #ededed;
    padding-bottom: 14px;
    margin-bottom: 18px;
}

.dashboard-card-six .card-body .notice-box-wrap .notice-list .post-date {
    display: inline-block;
    font-size: 14px;
    color: #ffffff;
    padding: 5px 14px;
    border-radius: 20px;
    margin-bottom: 14px;
}

.dashboard-card-six .card-body .notice-box-wrap .notice-list .notice-title {
    font-weight: 500;
    margin-bottom: 8px;
}

.dashboard-card-six .card-body .notice-box-wrap .notice-list .notice-title a {
    color: #111111;
}

.dashboard-card-six .card-body .notice-box-wrap .notice-list .entry-meta {
    font-size: 15px;
    color: #646464;
}

.dashboard-card-six .card-body .notice-box-wrap .notice-list .entry-meta span {
    color: #a4a4a4;
}

.dashboard-card-seven .social-media {
    border-radius: 4px;
    padding: 30px 20px;
    text-align: center;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.dashboard-card-seven .social-media .media {
    align-items: center;
    justify-content: center;
}

.dashboard-card-seven .social-media .media .social-icon {
    font-size: 24px;
    color: #ffffff;
}

.dashboard-card-seven .social-media .media .media-body {
    flex: inherit;
}

.dashboard-card-seven .social-media .media .media-body .item-title {
    margin-bottom: 0;
    color: #ffffff;
    font-size: 15px;
}

@media only screen and (max-width: 1199px) {
    .dashboard-card-seven .social-media .media .media-body {
        margin-top: 10px;
    }
}

.dashboard-card-seven .social-media .social-like {
    font-size: 26px;
    color: #ffffff;
}

/*=============== 7.2 Dashboard Page Two ==================*/
.dashboard-card-ten .card-body .student-info .media {
    margin-bottom: 6px;
}

@media only screen and (max-width: 1499px) {
    .dashboard-card-ten .card-body .student-info .media {
        margin-bottom: 30px;
    }
}

.dashboard-card-ten .card-body .student-info .media .item-img {
    background-color: #4dd0e1;
    height: 115px;
    width: 115px;
    line-height: 129px;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
}

.dashboard-card-ten .card-body .student-info .media .item-img img {
    margin-right: 5px;
}

.dashboard-card-ten .card-body .student-info .media .media-body .item-title {
    color: #111111;
    font-weight: 500;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1499px) {
    .dashboard-card-ten .card-body .student-info .info-table .table {
        width: 70%;
    }
}

@media only screen and (max-width: 991px) {
    .dashboard-card-ten .card-body .student-info .info-table .table {
        width: 100%;
    }
}

.dashboard-card-ten .card-body .student-info .info-table .table tbody tr td {
    border: none;
    padding: 8px;
}

.dashboard-card-eleven .card-body .table-box-wrap .search-form-box {
    margin-bottom: 18px;
}

/*=============== 7.3 Dashboard Page Three ==================*/
.dashboard-card-twelve .card-body .kids-details-wrap .kids-details-box {
    display: flex;
    background-color: #fafafa;
    padding: 40px 30px 28px;
}

@media only screen and (max-width: 575px) {
    .dashboard-card-twelve .card-body .kids-details-wrap .kids-details-box {
        display: block;
    }
}

.dashboard-card-twelve .card-body .kids-details-wrap .kids-details-box .item-img {
    height: 8rem;
    width: 8rem;
    background-color: #4dd0e1;
    border-radius: 50%;
    text-align: center;
    overflow: hidden;
    margin-right: 3rem;
}

@media only screen and (max-width: 575px) {
    .dashboard-card-twelve .card-body .kids-details-wrap .kids-details-box .item-img {
        margin: 0 auto 30px;
    }
}

.dashboard-card-twelve .card-body .kids-details-wrap .kids-details-box .item-content {
    flex: 1;
}

.dashboard-card-twelve .card-body .kids-details-wrap .kids-details-box .item-content .table tbody tr td {
    border: none;
    padding: 0 10px;
    color: #333333;
}

/*=============== 7.4 Dashboard Page Four ==================*/
.dashboard-summery-two {
    margin-bottom: 30px;
    background-color: #ffffff;
    padding: 30px 20px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px 0px rgba(229, 229, 229, 0.75);
    text-align: center;
}

.dashboard-summery-two .item-icon {
    position: relative;
    height: 85px;
    width: 85px;
    line-height: 85px;
    border-radius: 50%;
    text-align: center;
    margin: 0 auto 6px;
}

.dashboard-summery-two .item-icon i:before {
    margin-left: 0;
    font-size: 44px;
}

.dashboard-summery-two .item-content .item-title {
    color: #a8a8a8;
    margin-bottom: 2px;
}

@media only screen and (max-width: 1199px) {
    .dashboard-summery-two .item-content .item-title {
        font-size: 14px;
    }
}

.dashboard-summery-two .item-content .item-number {
    font-size: 28px;
    font-weight: 500;
    color: #111111;
}

@media only screen and (max-width: 1499px) {
    .dashboard-summery-two .item-content .item-number {
        font-size: 26px;
    }
}

@media only screen and (max-width: 1199px) {
    .dashboard-summery-two .item-content .item-number {
        font-size: 20px;
    }
}

/*=======================================================================
8. HELPER CLASSES
=========================================================================*/
/*=============== 8.1 Background ==================*/
.bg-transparent {
    background-color: transparent;
}

.bg-dark-high {
    background-color: #000000;
}

.bg-dark-medium {
    background-color: #111111;
}

.bg-dark-normal {
    background-color: #222222;
}

.bg-dark-low {
    background-color: #444444;
}

.bg-light {
    background-color: #ffffff !important;
}

.bg-ash {
    background-color: #f0f1f3 !important;
}

.bg-red {
    background-color: #ff0000;
}

.bg-orange-red {
    background-color: #ff0000;
}

.bg-blue {
    background-color: #304ffe;
}

.bg-blue-dark {
    background-color: #042954;
}

.bg-yellow {
    background-color: #fbd540;
}

.bg-yellow2 {
    background-color: #ffb822;
}

.bg-yellow3 {
    background-color: #f0a70d;
}

.bg-orange {
    background-color: #fbd540;
}

.bg-orange-peel {
    background-color: #ffa000;
}

.bg-skyblue {
    background-color: #40dfcd;
}

.bg-pink {
    background-color: #f939a1;
}

.bg-pink2 {
    background-color: #ff5252;
}

.bg-pink3 {
    background-color: #ef3232;
}

.bg-mauvelous {
    background-color: #f48fb1;
}

.bg-light-red {
    background-color: #ffeaea;
}

.bg-light-yellow {
    background-color: #fff2d8;
}

.bg-light-blue {
    background-color: #e1f1ff;
}

.bg-dodger-blue {
    background-color: #2196f3;
}

.bg-violet-blue {
    background-color: #a864a8;
}

.bg-light-green {
    background-color: #d1f3e0;
}

.bg-dark-pastel-green {
    background-color: #00c853;
}

.bg-light-sea-green {
    background-color: #1cbbb4;
}

.bg-light-green2 {
    background-color: #64d9bc;
}

.bg-light-green3 {
    background-color: #4ec9aa;
}

.bg-light-magenta {
    background-color: #f3e5f5;
}

.bg-martini {
    background-color: #bcaaa4;
}

.bg-true-v {
    background-color: #9575cd;
}

.bg-wild-strawberry {
    background-color: #ff4081;
}

.bg-fb {
    background-color: #546ca9;
}

.bg-fb2 {
    background-color: #5867dd;
}

.bg-fb3 {
    background-color: #4655c8;
}

.bg-gplus {
    background-color: #f81b1b;
}

.bg-twitter {
    background-color: #45a0f0;
}

.bg-linkedin {
    background-color: #1565c0;
}

.bg-git {
    background-color: #997f75;
}

.sidebar-color {
    background-color: #042954;
}

.bg-gradient-fb {
    background: -webkit-gradient(linear, left top, right top, from(#546ca9), to(#5c77bc));
    background: -webkit-linear-gradient(left, #546ca9, #5c77bc);
    background: -o-linear-gradient(left, #546ca9, #5c77bc);
    background: linear-gradient(to right, #546ca9, #5c77bc);
}

.bg-gradient-twitter {
    background: -webkit-gradient(linear, left top, right top, from(#1f89e5), to(#64b5f6));
    background: -webkit-linear-gradient(left, #1f89e5, #64b5f6);
    background: -o-linear-gradient(left, #1f89e5, #64b5f6);
    background: linear-gradient(to right, #1f89e5, #64b5f6);
}

.bg-gradient-gplus {
    background: -webkit-gradient(linear, left top, right top, from(#e84642), to(#f81b1b));
    background: -webkit-linear-gradient(left, #e84642, #f81b1b);
    background: -o-linear-gradient(left, #e84642, #f81b1b);
    background: linear-gradient(to right, #e84642, #f81b1b);
}

.bg-gradient-linked {
    background: -webkit-gradient(linear, left top, right top, from(#1565c0), to(#314ce0));
    background: -webkit-linear-gradient(left, #1565c0, #314ce0);
    background: -o-linear-gradient(left, #1565c0, #314ce0);
    background: linear-gradient(to right, #1565c0, #314ce0);
}

.btn-gradient-yellow {
    background-color: #ffae01;
}
.btn-gradient-green {
    background-color: #156C42;
}

.gradient-dodger-blue {
    background: -webkit-gradient(linear, left top, right top, from(#2196f3), to(#21bff3));
    background: -webkit-linear-gradient(left, #2196f3, #21bff3);
    background: -o-linear-gradient(left, #2196f3, #21bff3);
    background: linear-gradient(to right, #2196f3, #21bff3);
}

.gradient-orange-peel {
    background: -webkit-gradient(linear, left top, right top, from(#ffa000), to(#ff7200));
    background: -webkit-linear-gradient(left, #ffa000, #ff7200);
    background: -o-linear-gradient(left, #ffa000, #ff7200);
    background: linear-gradient(to right, #ffa000, #ff7200);
}

.gradient-pastel-green {
    background: -webkit-gradient(linear, left top, right top, from(#43a047), to(#66bb6a));
    background: -webkit-linear-gradient(left, #43a047, #66bb6a);
    background: -o-linear-gradient(left, #43a047, #66bb6a);
    background: linear-gradient(to right, #43a047, #66bb6a);
}

/*=============== 8.2 Border ==================*/
.border-dodger-blue {
    border: 1px solid #2196f3 !important;
}

.border-mauvelous {
    border: 1px solid #f48fb1;
}

.border-orange-peel {
    border: 1px solid #ffa000 !important;
}

.border-dark-pastel-green {
    border: 1px solid #00c853 !important;
}

.border-red {
    border: 1px solid #ff0000 !important;
}

.border-true-v {
    border: 1px solid #9575cd;
}

.border-violet-blue {
    border: 1px solid #a864a8;
}

.border-light-sea-green {
    border: 1px solid #1cbbb4;
}

.border-martini {
    border: 1px solid #bcaaa4;
}

.border-yellow {
    border: 1px solid #fbd540;
}

.border-orange-red {
    border: 1px solid #ff0000;
}

.no-radius {
    border-radius: 0 !important;
}

.radius-4 {
    border-radius: 4px;
}

.radius-30 {
    border-radius: 30px;
}

.radius-50 {
    border-radius: 50%;
}

/*=============== 8.3 Height ==================*/
.height-auto {
    height: auto !important;
}

.m-height-580 {
    max-height: 58rem !important;
}

.m-height-440 {
    max-height: 44rem !important;
}

.m-height-660 {
    max-height: 66rem !important;
}

/*=============== 8.4 Margin ==================*/
.mg-0 {
    margin: 0;
}

.mg-x-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.mg-y-0 {
    margin-right: 0;
    margin-left: 0;
}

.mg-t-0 {
    margin-top: 0;
}

.mg-r-0 {
    margin-right: 0;
}

.mg-b-0 {
    margin-bottom: 0;
}

.mg-l-0 {
    margin-left: 0;
}

.mg-t-4 {
    margin-top: 4px;
}

.mg-t-5 {
    margin-top: 5px;
}

.mg-t-6 {
    margin-top: 6px !important;
}

.mg-t-7 {
    margin-top: 7px;
}

.mg-t-8 {
    margin-top: 8px;
}

.mg-t-9 {
    margin-top: 9px;
}

.mg-t-10 {
    margin-top: 10px;
}

.mg-t-11 {
    margin-top: 11px;
}

.mg-t-12 {
    margin-top: 12px;
}

.mg-t-13 {
    margin-top: 13px;
}

.mg-t-14 {
    margin-top: 14px;
}

.mg-t-15 {
    margin-top: 15px;
}

.mg-t-16 {
    margin-top: 16px;
}

.mg-t-17 {
    margin-top: 17px;
}

.mg-t-18 {
    margin-top: 18px;
}

.mg-t-19 {
    margin-top: 19px;
}

.mg-t-20 {
    margin-top: 20px;
}

.mg-t-22 {
    margin-top: 22px;
}

.mg-t-23 {
    margin-top: 23px;
}

.mg-t-24 {
    margin-top: 24px;
}

.mg-t-25 {
    margin-top: 25px;
}

.mg-t-26 {
    margin-top: 26px;
}

.mg-t-27 {
    margin-top: 27px;
}

.mg-t-28 {
    margin-top: 28px;
}

.mg-t-29 {
    margin-top: 29px;
}

.mg-t-30 {
    margin-top: 30px;
}

.mg-l-4 {
    margin-left: 4px;
}

.mg-l-5 {
    margin-left: 5px;
}

.mg-l-6 {
    margin-left: 6px;
}

.mg-l-7 {
    margin-left: 7px;
}

.mg-l-8 {
    margin-left: 8px;
}

.mg-l-9 {
    margin-left: 9px;
}

.mg-l-10 {
    margin-left: 10px;
}

.mg-l-11 {
    margin-left: 11px;
}

.mg-l-12 {
    margin-left: 12px;
}

.mg-l-13 {
    margin-left: 13px;
}

.mg-l-14 {
    margin-left: 14px;
}

.mg-l-15 {
    margin-left: 15px;
}

.mg-l-16 {
    margin-left: 16px;
}

.mg-l-17 {
    margin-left: 17px;
}

.mg-l-18 {
    margin-left: 18px;
}

.mg-l-19 {
    margin-left: 19px;
}

.mg-l-20 {
    margin-left: 20px;
}

.mg-l-22 {
    margin-left: 22px;
}

.mg-b-4 {
    margin-bottom: 4px;
}

.mg-b-5 {
    margin-bottom: 5px;
}

.mg-b-6 {
    margin-bottom: 6px !important;
}

.mg-b-7 {
    margin-bottom: 7px;
}

.mg-b-8 {
    margin-bottom: 8px;
}

.mg-b-9 {
    margin-bottom: 9px;
}

.mg-b-10 {
    margin-bottom: 10px;
}

.mg-b-11 {
    margin-bottom: 11px;
}

.mg-b-12 {
    margin-bottom: 12px;
}

.mg-b-13 {
    margin-bottom: 13px;
}

.mg-b-14 {
    margin-bottom: 14px;
}

.mg-b-15 {
    margin-bottom: 15px;
}

.mg-b-16 {
    margin-bottom: 16px;
}

.mg-b-17 {
    margin-bottom: 17px;
}

.mg-b-18 {
    margin-bottom: 18px;
}

.mg-b-19 {
    margin-bottom: 19px;
}

.mg-b-20 {
    margin-bottom: 20px;
}

.mg-b-22 {
    margin-bottom: 22px;
}

.mg-b-25 {
    margin-bottom: 25px;
}

.mg-r-4 {
    margin-right: 4px;
}

.mg-r-5 {
    margin-right: 5px;
}

.mg-r-6 {
    margin-right: 6px;
}

.mg-r-7 {
    margin-right: 7px;
}

.mg-r-8 {
    margin-right: 8px;
}

.mg-r-9 {
    margin-right: 9px;
}

.mg-r-10 {
    margin-right: 10px;
}

.mg-r-11 {
    margin-right: 11px;
}

.mg-r-12 {
    margin-right: 12px;
}

.mg-r-13 {
    margin-right: 13px;
}

.mg-r-14 {
    margin-right: 14px;
}

.mg-r-15 {
    margin-right: 15px;
}

.mg-r-16 {
    margin-right: 16px;
}

.mg-r-17 {
    margin-right: 17px;
}

.mg-r-18 {
    margin-right: 18px;
}

.mg-r-19 {
    margin-right: 19px;
}

.mg-r-20 {
    margin-right: 20px;
}

.mg-r-22 {
    margin-right: 22px;
}

/*=============== 8.5 Misc ==================*/
.pseudo-bg-Aquamarine:after {
    background-color: #1de9b6;
}

.pseudo-bg-blue:after {
    background-color: #417dfc;
}

.pseudo-bg-yellow:after {
    background-color: #ffaa01;
}

.pseudo-bg-red:after {
    background-color: #ff0000;
}

.pseudo-bg-pink:after {
    background-color: #f939a1;
}

.header-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.shadow-dodger-blue {
    -webkit-box-shadow: 0px 6px 8px 0px rgba(33, 150, 243, 0.4);
    box-shadow: 0px 6px 8px 0px rgba(33, 150, 243, 0.4);
}

.shadow-dark-pastel-green {
    -webkit-box-shadow: 0px 6px 8px 0px rgba(0, 200, 83, 0.4);
    box-shadow: 0px 6px 8px 0px rgba(0, 200, 83, 0.4);
}

.shadow-true-v {
    -webkit-box-shadow: 0px 6px 8px 0px rgba(149, 117, 205, 0.4);
    box-shadow: 0px 6px 8px 0px rgba(149, 117, 205, 0.4);
}

.shadow-red {
    -webkit-box-shadow: 0px 6px 8px 0px rgba(255, 0, 0, 0.4);
    box-shadow: 0px 6px 8px 0px rgba(255, 0, 0, 0.4);
}

.shadow-martini {
    -webkit-box-shadow: 0px 6px 8px 0px rgba(188, 170, 164, 0.4);
    box-shadow: 0px 6px 8px 0px rgba(188, 174, 164, 0.4);
}

.shadow-violet-blue {
    -webkit-box-shadow: 0px 6px 8px 0px rgba(168, 100, 168, 0.4);
    box-shadow: 0px 6px 8px 0px rgba(168, 100, 168, 0.4);
}

.shadow-light-sea-green {
    -webkit-box-shadow: 0px 6px 8px 0px rgba(28, 187, 180, 0.4);
    box-shadow: 0px 6px 8px 0px rgba(28, 187, 180, 0.4);
}

.shadow-orange-peel {
    -webkit-box-shadow: 0px 6px 8px 0px rgba(255, 160, 0, 0.4);
    box-shadow: 0px 6px 8px 0px rgba(255, 160, 0, 0.4);
}

.shadow-orange-red {
    -webkit-box-shadow: 0px 6px 8px 0px rgba(255, 61, 0, 0.4);
    box-shadow: 0px 6px 8px 0px rgba(255, 61, 0, 0.4);
}

.hover-fb:hover {
    background: #485c8f;
}

.hover-twitter:hover {
    background: #1f89e5;
}

.hover-gplus:hover {
    background: #db1a1a;
}

.hover-linked:hover {
    background: #1559a7;
}

.alert {
    padding: 12px 25px;
}

/*=============== 8.6 Padding ==================*/
.pd-0 {
    padding: 0;
}

.pd-x-0 {
    padding-right: 0;
    padding-left: 0;
}

.pd-y-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.pd-y-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
}

.pd-t-0 {
    padding-top: 0;
}

.pd-r-0 {
    padding-right: 0;
}

.pd-b-0 {
    padding-bottom: 0;
}

.pd-l-0 {
    padding-left: 0;
}

.pd-t-4 {
    padding-top: 4px;
}

.pd-t-5 {
    padding-top: 5px;
}

.pd-t-6 {
    padding-top: 6px;
}

.pd-t-7 {
    padding-top: 7px;
}

.pd-t-8 {
    padding-top: 8px;
}

.pd-t-9 {
    padding-top: 9px;
}

.pd-t-10 {
    padding-top: 10px;
}

.pd-t-11 {
    padding-top: 11px;
}

.pd-t-12 {
    padding-top: 12px;
}

.pd-t-13 {
    padding-top: 13px;
}

.pd-t-14 {
    padding-top: 14px;
}

.pd-t-15 {
    padding-top: 15px;
}

.pd-t-16 {
    padding-top: 16px;
}

.pd-t-17 {
    padding-top: 17px;
}

.pd-t-18 {
    padding-top: 18px;
}

.pd-t-19 {
    padding-top: 19px;
}

.pd-t-20 {
    padding-top: 20px;
}

.pd-t-22 {
    padding-top: 22px;
}

.pd-l-4 {
    padding-left: 4px;
}

.pd-l-5 {
    padding-left: 5px;
}

.pd-l-6 {
    padding-left: 6px;
}

.pd-l-7 {
    padding-left: 7px;
}

.pd-l-8 {
    padding-left: 8px;
}

.pd-l-9 {
    padding-left: 9px;
}

.pd-l-10 {
    padding-left: 10px;
}

.pd-l-11 {
    padding-left: 11px;
}

.pd-l-12 {
    padding-left: 12px;
}

.pd-l-13 {
    padding-left: 13px;
}

.pd-l-14 {
    padding-left: 14px;
}

.pd-l-15 {
    padding-left: 15px;
}

.pd-l-16 {
    padding-left: 16px;
}

.pd-l-17 {
    padding-left: 17px;
}

.pd-l-18 {
    padding-left: 18px;
}

.pd-l-19 {
    padding-left: 19px;
}

.pd-l-20 {
    padding-left: 20px;
}

.pd-l-22 {
    padding-left: 22px;
}

.pd-b-4 {
    padding-bottom: 4px;
}

.pd-b-5 {
    padding-bottom: 5px;
}

.pd-b-6 {
    padding-bottom: 6px;
}

.pd-b-7 {
    padding-bottom: 7px;
}

.pd-b-8 {
    padding-bottom: 8px;
}

.pd-b-9 {
    padding-bottom: 9px;
}

.pd-b-10 {
    padding-bottom: 10px;
}

.pd-b-11 {
    padding-bottom: 11px;
}

.pd-b-12 {
    padding-bottom: 12px;
}

.pd-b-13 {
    padding-bottom: 13px;
}

.pd-b-14 {
    padding-bottom: 14px;
}

.pd-b-15 {
    padding-bottom: 15px;
}

.pd-b-16 {
    padding-bottom: 16px;
}

.pd-b-17 {
    padding-bottom: 17px;
}

.pd-b-18 {
    padding-bottom: 18px;
}

.pd-b-19 {
    padding-bottom: 19px;
}

.pd-b-20 {
    padding-bottom: 20px;
}

.pd-b-22 {
    padding-bottom: 22px;
}

.pd-r-4 {
    padding-right: 4px;
}

.pd-r-5 {
    padding-right: 5px;
}

.pd-r-6 {
    padding-right: 6px;
}

.pd-r-7 {
    padding-right: 7px;
}

.pd-r-8 {
    padding-right: 8px;
}

.pd-r-9 {
    padding-right: 9px;
}

.pd-r-10 {
    padding-right: 10px;
}

.pd-r-11 {
    padding-right: 11px;
}

.pd-r-12 {
    padding-right: 12px;
}

.pd-r-13 {
    padding-right: 13px;
}

.pd-r-14 {
    padding-right: 14px;
}

.pd-r-15 {
    padding-right: 15px;
}

.pd-r-16 {
    padding-right: 16px;
}

.pd-r-17 {
    padding-right: 17px;
}

.pd-r-18 {
    padding-right: 18px;
}

.pd-r-19 {
    padding-right: 19px;
}

.pd-r-20 {
    padding-right: 20px;
}

.pd-r-22 {
    padding-right: 22px;
}

/*=============== 8.7 Position ==================*/
.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.position-fixed {
    position: fixed;
}

.position-static {
    position: static;
}

/*=============== 8.8 Text ==================*/
.text-10 {
    font-size: 10px;
}

.text-11 {
    font-size: 11px;
}

.text-12 {
    font-size: 12px;
}

.text-13 {
    font-size: 13px;
}

.text-14 {
    font-size: 14px;
}

.text-15 {
    font-size: 15px;
}

.text-16 {
    font-size: 16px;
}

.text-17 {
    font-size: 17px;
}

.text-18 {
    font-size: 18px;
}

.text-19 {
    font-size: 19px;
}

.text-20 {
    font-size: 20px;
}

.text-24 {
    font-size: 24px;
}

.text-28 {
    font-size: 28px;
}

.text-30 {
    font-size: 30px;
}

.text-32 {
    font-size: 32px;
}

.text-34 {
    font-size: 34px;
}

.font-bold {
    font-weight: 700;
}

.font-semibold {
    font-weight: 600;
}

.font-medium {
    font-weight: 500;
}

.font-normal {
    font-weight: 400;
}

.font-light {
    font-weight: 300;
}

.text-light {
    color: #ffffff !important;
}

.text-mute-high {
    color: #9f9f9f;
}

.text-mute-medium {
    color: #a5a5a5;
}

.text-mute-low {
    color: #a8a8a8;
}

.text-dark-high {
    color: #000000;
}

.text-dark-medium {
    color: #111111;
}

.text-dark-normal {
    color: #222222;
}

.text-dark-low {
    color: #444444;
}

.text-blue {
    color: #3f7afc;
}

.text-green {
    color: #3cb878;
}

.text-orange {
    color: #ffa001;
}

.text-red {
    color: #ff0000;
}

.text-magenta {
    color: #8e24aa;
}

.text-dodger-blue {
    color: #2196f3;
}

.text-mauvelous {
    color: #f48fb1;
}

.text-orange-peel {
    color: #ffa000;
}

.text-dark-pastel-green {
    color: #00c853;
}

.text-true-v {
    color: #9575cd;
}

.text-violet-blue {
    color: #a864a8;
}

.text-light-sea-green {
    color: #1cbbb4;
}

.text-martini {
    color: #bcaaa4;
}

.text-yellow {
    color: #fbd540;
}

.text-orange-red {
    color: #ff0000;
}