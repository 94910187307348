
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800');


@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Bengali:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root{
  --Site-primary-color: #156C42;
}

body {
  margin: 0 auto;
  font-family: 'Noto Serif Bengali', serif;
}

body,
html {
  height: 100%;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  border: none;
  outline: none;
}

button:active,
button:focus {
  border: none;
  outline: none;
}

img {
  max-width: 100%;
}

.footer-top {
  background: #282828;
  padding: 50px 0;
  color: #FFF;
}

.footer-bottom {
  background: #101010;
  padding: 30px 0;
  color: #FFF;
}

.header-top {
  background: #222222;
  transition: .3s;
  height: 48px;
  transition: .3s;
}

.header-main-inner {
  background: #156C42;
  border-bottom: 2px solid #E4D296;
  padding: 15px 0;
  transition: .3s;
}

.top-notice ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-notice ul li {
  display: inline-block;
  padding-right: 30px;
  font-size: 12px;
  position: relative;
  padding-left: 30px;
}

.top-notice ul li:last-child {
  padding: 0;
}

.top-notice {
  color: #FFF;
  display: block;
}

.top-notice li svg {
  color: #156C42;
  font-size: 16px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 1px;
}

.mulitlang a {
  background: #156C42;
  color: #FFF;
  padding: 14px 22px;
  font-weight: 600;
  text-transform: uppercase;
  transition: .3s;
  border: 1px solid #156C42;
  display: block;
}

.mulitlang a:hover {
  background: #FFF;
  color: #156C42;
}

.header-center {
  display: flex;
  align-items: center;
}

.logo {
  min-width: 350px;
  transition: .3s;
}

.header-center-contacts {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 45px;
  justify-content: end;
  -webkit-justify-content: flex-end;
}

.single-header-contact {
  width: 35%;
}

.header-center .socials {
  width: 27%;
  text-align: right;
}

.single-header-contact p {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.single-header-contact {
  position: relative;
  padding-left: 55px;
}

.single-header-contact svg {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #EBE9EB;
  display: inline-block;
  font-size: 37px;
  padding: 10px;
  width: auto !important;
  color: #156C42;
  border-radius: 8px;
}

.socials li {
  display: inline-block;
}

.socials li a {
  color: #999999;
  display: block;
  background: #EBE9EB;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 28px;
  border-radius: 10px;
  font-size: 15px;
}

.socials li a:hover {
  background: #156C42;
  color: #FFF;
}

.header-center-inner {
  position: relative;
  transition: .3s;
  padding: 25px 0;
}

.header-contents p:last-child {
  color: #7B8182;
  font-size: 13px;
  font-weight: 400;
}

.main-menu {
  width: 100%;
}

.extra-menu {
  display: flex;
  align-items: center;
}

.menu-search {
  position: relative;
  width: 100%;
  min-width: 150px;
  height: 38px;
  display: block;
  padding: 10px;
  color: #FFF;
  margin-left: 25px;
}

.main-menu li {
  display: inline-block;
  margin-right: 30px;
  font-size: 15px;
}

.main-menu li a {
  display: block;
  color: #FFF;
}

.main-menu li.current_nav_menu a,
.main-menu li a:hover {
  color: #BBFCDD;
}

.single-hero-item {
  position: relative;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.single-hero-item::before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url('../assets/images/banner-overlay.png');
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero-contents {
  padding: 170px 0 170px 0;
  color: #FFF;
}

.hero-contents p {
  margin: 35px 0;
  font-size: 15px;
  line-height: 28px;
}

.hero-contents ul li {
  display: inline-block;
  margin-right: 22px;
}

.hero-contents li a {
  display: block;
  border: 1px solid;
  color: #FFF;
  font-weight: 600;
  padding: 10px 50px;
  transition: .2s;
}

.hero-contents li a:hover {
  background: #156C42;
  border-color: #156C42;
}

.hero-carousel {
  margin-bottom: -25px;
}

.hero-carousel .owl-dots {
  position: relative;
  bottom: 60px;
  max-width: 1140px;
  margin: auto;
  padding: 0 15px;
}

.owl-dots span {
  display: inline-block;
  width: 13px;
  height: 12px;
  display: inline-block;
  background: #FFF;
  border-radius: 4px;
  margin-right: 8px;
  border: 2px solid;
  border-color: #FFF;
  transition: .3s;
}

.owl-dots button.active span {
  background: transparent;
  border-color: #156C42;
}

.donation a {
  color: #FFF;
  display: block;
  border: 1px solid;
  padding: 6px 23px;
  transition: .2s;
}

.donation a:hover {
  background: #FFF;
  border: 1px solid white;
  color: #156C42;
}

.menu-search input {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, .7);
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  color: #FFF;
  font-size: 14px;
  padding-left: 10px;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
}

.menu-search svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 13px;
  cursor: pointer;
}

header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background: #FFF;
  transition: .3s;
}

spacer {
  display: block;
  height: 220px;
  background: #156C42;
  transition: .3s;
}

#diffrences {
  padding: 100px 0;
}

.section-title {
  text-align: center;
  margin-bottom: 70px;
}

.section-title p {
  margin: 0;
  font-size: 15px;
  max-width: 1035px;
  margin: auto;
  line-height: 30px;
}

body {
  color: #212121;
}

.section-title h2 {
  margin-bottom: 15px;
}

.single-difference-item {
  text-align: center;
  padding: 30px;
  box-shadow: 0 0 5px rgba(0, 0, 0, .1);
  border-radius: 30px;
  margin: 15px;
}

.single-difference-item img {
  max-width: 50px;
  margin: auto;
}

.single-difference-item h3 {
  margin: 20px 0;
  font-size: 20px;
  font-weight: 500;
}

.single-difference-item p {
  font-size: 15px;
  color: #748182;
}

.single-difference-item a {
  background: #156C42;
  color: #FFF;
  display: inline-block;
  padding: 10px 40px;
  border: 1px solid #156C42;
  transition: .2s;
  margin-top: 25px;
}

.single-difference-item a:hover {
  background: transparent;
  color: #156C42;
}

#services {
  background: #EEF1F4;
  padding: 80px 0;
}

.single-services {
  position: relative;
  padding-left: 80px;
  font-size: 16px;
  margin-bottom: 110px;
}

.services-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #156C42;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 57px;
  border-radius: 50%;
}

.services-icon img {
  max-width: 29px;
}

.cta-btn {
  text-align: center;
}

.cta-btn a {
  display: inline-block;
  color: #767676;
  padding: 9px 30px;
  border: 2px solid #767676;
  transition: .2s;
}

.cta-btn a:hover {
  background: #767676;
  color: #FFF;
}

#services {
  background: #EEF1F4;
  padding: 80px 0;
}

.single-services {
  position: relative;
  padding-left: 80px;
  font-size: 16px;
  margin-bottom: 110px;
}

.services-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #156C42;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 57px;
  border-radius: 50%;
}

.services-icon img {
  max-width: 29px;
}

.cta-btn {
  text-align: center;
}

.cta-btn a {
  display: inline-block;
  color: #767676;
  padding: 9px 30px;
  border: 2px solid #767676;
  transition: .2s;
}

.cta-btn a:hover {
  background: #767676;
  color: #FFF;
}

.conter-inner {
  padding: 80px 0;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}

.conter-inner::before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .6);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.single-counter {
  text-align: center;
  color: #FFF;
}

.single-counter strong {
  font-size: 46px;
  margin: 0;
}

.single-counter p {
  margin: 0;
}

#motive {
  padding: 80px 0;
}

#motive .row {
  align-items: center;
}

.motive-head h2 {
  /* font-size: ; */
  line-height: 50px;
}

.motive-contents {
  position: relative;
  padding-left: 40px;
}

.motive-contents::before {
  content: "";
  width: 2px;
  height: 80%;
  background: rgba(0, 0, 0, .1);
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
}

.motive-contents h3 {
  color: #156C42;
  font-size: 20px;
  line-height: 43px;
  margin-bottom: 50px;
}

.motive-contents p {
  margin: 0;
  color: #156C42;
  font-size: 14px;
}

#team {
  background: #156C42;
  padding: 70px 0;
  color: #FFF;
}

.team-image {
  background-color: #E3F1FB;
  padding-top: 30px;
  border-radius: 10px 10px 0 0;
  text-align: center;
}

.team-member-details {
  text-align: center;
  background: #E4D296;
  padding: 15px 10px;
  position: relative;
  border-radius: 0 0 10px 10px;
}

.team-member-details h4 {
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  color: #156C42;
  margin-bottom: 5px;
}

.team-member-details p {
  color: #212121;
  margin: 0;
  font-size: 12px;
}

.team-member-details a {
  color: #156C42;
  font-size: 11px;
  display: inline-block;
  border: 1px solid;
  padding: 4px 15px;
  border-radius: 6px;
  transition: .2s;
  /* position: absolute; */
  right: 7px;
  bottom: 15px;
}

.team-member-details a:hover {
  background: #156C42;
  color: #FFF;
  border-color: transparent;
}

.team-image img {
  text-align: center;
  /* max-height: 228px; */
  width: auto !important;
  margin: auto;
}

#news {
  padding: 80px 0;
}

#news .section-title {
  max-width: 650px;
  margin: auto;
  margin-bottom: 50px;
}

.news-image {
  min-height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.single-news {
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 8px rgba(0, 0, 0, .1);
}

.news-details {
  padding: 26px;
}

.news-details h4 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 15px;
}

.news-details p {
  color: #748182;
  font-size: 14px;
  font-weight: 500;
  min-height: 90px;
}

.news-details a {
  color: inherit;
  font-weight: 600;
  font-size: 15px;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, .1);
  padding: 9px 27px;
  transition: .2s;
  border-radius: 4px;
}

.news-details a:hover {
  /* background: #212121; */
  background-color: var(--Site-primary-color);
  color: #FFF;
}


#testimonial {
  background: #EEF1F4;
  padding: 80px 0;
}

.single-testimonial {
  background: #FFF;
  position: relative;
  border-radius: 35px;
  padding: 35px;
  text-align: center;
  padding-top: 56px;
  margin-top: 60px;
}

.testimonial-autor-details h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.testimonial-autor-details p {
  font-weight: 500;
  font-size: 15px;
}

.testimonial-author {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: -50px;
  transform: translateX(-50%);
}

.testimonial-autor-details {
  margin-bottom: 40px;
}

.testimonial-contents p {
  line-height: 28px;
}

header.sticky .header-top {
  height: 0;
  opacity: 0;
  pointer-events: none;
  margin: 0;
  padding: 0;
}

header.sticky .header-center-inner {
  padding: 7px 0;
}

header.sticky .logo {
  min-width: auto;
  max-width: 250px;
}

header.sticky .header-center .socials {
  width: 20%;
}

header.sticky .header-main-inner {
  padding: 7px 0;
}

.copyright p {
  font-size: 11px;
  margin: 0;
}

.footer-bottom p {
  margin: 0;
}

.footer-bottom .row {
  align-items: center;
}

footer,
footer p,
footer li,
footer a {
  color: #888;
}

.footer-policy {
  text-align: center;
}

.footer-policy li {
  display: inline-block;
}

.footer-policy li a {
  font-size: 12px;
  display: block;
  margin-left: 10px;
}

.socials {
  text-align: right;
}

footer .socials li a {
  background: #5B5B5B;
  color: #FFF;
}

footer .socials li {
  margin-left: 10px;
}

footer h5 {
  font-size: 14px;
  margin-bottom: 10px;
  min-height: 35px;
}

footer a,
footer li {
  font-size: 13px;
  position: relative;
  color: #C6C6C6;
}

.address li {
  position: relative;
  padding-left: 40px;
  margin-bottom: 24px;
  display: block;
  min-height: 30px;
}

.address li svg {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 31px;
  color: #FFF;
  background: #3E3E3E;
  padding: 9px;
  display: inline-block;
  width: auto !important;
  border-radius: 10px;
}

.quick-link a::before {
  content: "\f30b";
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  left: 0;
  top: 57%;
  transform: translateY(-50%);
}

.quick-link a {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  display: block;
}

footer a:hover {
  color: #156C42;
}

#diffrences .owl-dots {
  text-align: center;
  margin-top: 50px;
}

#diffrences .owl-dots span {
  border-color: #D9D9D9;
  border-radius: 50%;
}

#diffrences .owl-dots .active span {
  background: #D9D9D9;
}

#team .owl-dots {
  text-align: center;
  margin-top: 60px;
  padding-bottom: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, .2);
}

#team .owl-dots span {
  border-radius: 50%;
  background: transparent;
  border-color: #FFF;
}

#team .owl-dots .active span {
  background: #FFF;
}

#testimonial .owl-dots {
  text-align: center;
  margin-top: 60px;
}

#testimonial .owl-dots span {
  background: #D0D4D9;
  border-color: #D0D4D9;
  border-radius: 50%;
}

#testimonial .owl-dots .active span {
  background: transparent;
}

.collapse-nav-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*contact-page*/

.banner-inner {
  padding: 40px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  color: #FFF;
}

.banner-inner h1 {
  position: relative;
  display: inline-block;
  padding-bottom: 15px;
  margin-bottom: 23px;
}

.banner-inner h1::before {
  content: "";
  width: 70px;
  height: 2px;
  background: #FFF;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  opacity: .5;
}

.breadcrumb {
  background: transparent;
  text-align: center;
  display: block;
  padding: 0;
  margin: 0;
}

.breadcrumb li {
  display: inline-block;
  position: relative;
  padding-right: 38px;
  margin-right: 12px;
}

.breadcrumb li:last-child {
  margin: 0;
  padding: 0;
}

.breadcrumb li:last-child::before {
  display: none;
}

.breadcrumb li::before {
  content: ">>";
  font-size: 20px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}

#boxs {
  padding: 100px 0;
}

.grid-boxs {
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.single-grid-box {
  width: 25%;
  text-align: center;
  border: 1px solid #156C42;
  border-left: 0;
  padding: 35px 8px;
}

.single-grid-box:first-child {
  border-left: 1px solid #156C42;
}

.box-icon {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: #156C42;
  color: #FFF;
  line-height: 45px;
  transform: rotate(45deg);
  border: 1px solid;
  border-color: #156C42;
}

.box-icon svg {
  transform: rotate(-45deg);
}

/* .box-icon:hover {} */

.box-contents h3 {
  margin: 30px 0;
  font-size: 21px;
  font-weight: bold;
  margin-top: 38px;
  position: relative;
}

.box-contents li {
  font-size: 13px;
  margin-bottom: 6px;
}

.box-contents li:last-child {
  margin: 0;
}

.box-contents h3::before {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  left: -16px;
  background: #156C42;
  transform: rotate(45deg);
}

.single-grid-box:first-child .box-contents h3::before {
  display: none;
}

#map {
  background-color: #EEF1F4;
  position: relative;
}

.map-iframe {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 50%;
}

.map-iframe iframe {
  width: 100%;
  height: 100%;
}

.contact-form-inner {
  max-width: 50%;
  margin: auto;
  margin-right: 0;
  padding: 80px;
  padding-right: 0;
}

.contact-form {
  margin-top: 40px;
}

.single-input input,
.single-input textarea {
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding: 10px;
  display: block;
  font-size: 14px;
  color: #000;
  background: transparent;
}

.single-input {
  margin-bottom: 15px;
}

.single-input-btn input {
  background: #156C42;
  color: #FFF;
  border: 1px solid #156C42;
  padding: 8px 35px;
  border-radius: 50px;
  transition: 0.3s;
}

.single-input-btn input:hover {
  background: transparent;
  color: #156C42;
}

.single-input-btn {
  text-align: right;
  margin-top: 30px;
}

#map {
  background-color: #EEF1F4;
  position: relative;
}

.map-iframe {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 50%;
}

.map-iframe iframe {
  width: 100%;
  height: 100%;
}

.contact-form-inner {
  max-width: 50%;
  margin: auto;
  margin-right: 0;
  padding: 80px;
  padding-right: 0;
}

.contact-form {
  margin-top: 40px;
}

.single-input input,
.single-input textarea {
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding: 10px;
  display: block;
  font-size: 14px;
  color: #000;
  background: transparent;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
}

.single-input {
  margin-bottom: 15px;
}

.single-input-btn input {
  background: #156C42;
  color: #FFF;
  border: 1px solid #156C42;
  padding: 8px 35px;
  border-radius: 50px;
  transition: .3s;
}

.single-input-btn input:hover {
  background: transparent;
  color: #156C42;
}

.single-input-btn {
  text-align: right;
  margin-top: 30px;
}

.single-input input:focus,
.single-input textarea:focus {
  border-color: #156C42;
}

.single-input textarea {
  min-height: 170px;
}

.box-contents a {
  color: inherit;
}

.box-contents a:hover {
  color: #156C42;
}

.breadcrumb a {
  color: #FFF;
}

.breadcrumb a:hover {
  color: #156C42;
}



@media(max-width: 1199px) {
  .logo {
    min-width: inherit;
    max-width: 250px;
  }

  .hero-contents h1 {
    font-size: 31px;
  }

  .single-difference-item {
    padding: 30px 20px;
  }

  .single-difference-item p {
    min-height: 115px;
  }

  .main-menu li {
    margin-right: 25px;
  }

  spacer {
    height: 200px;
  }

  .box-contents li {
    word-break: break-all;
  }
}

@media(max-width: 991px) {

  .logo {
    max-width: 400px;
  }

  .header-center .socials {
    display: none;
  }

  .single-header-contact {
    width: 47%;
  }

  header.sticky .logo {
    max-width: 150px;
  }

  .header-center-contacts {
    padding-left: 15px;
  }

  .header-top {
    display: none;
  }

  #diffrences {
    padding: 60px 0;
  }

  #motive {
    padding: 60px 0;
  }

  .motive-head h2 {
    font-size: 26px;
  }

  #services {
    padding: 60px 0;
  }

  .single-services {
    margin-bottom: 60px;
  }

  .news-details {
    padding: 20px 10px;
  }

  .single-testimonial {
    padding: 25px;
    padding-top: 60px;
  }

  .testimonial-autor-details {
    margin-bottom: 30px;
  }

  spacer {
    height: 165px;
  }

  /*hamburger-menu*/

  .nav-header {
    position: relative;
    width: 45px;
    height: 45px;
    background: 0 0;
    cursor: pointer;
    z-index: 3;
  }

  .hamburger {
    position: absolute;
    width: 35px;
    height: 2px;
    background: #FFF;
    top: 50%;
    transform: translate(0%, -50%);
    right: 15px;
  }

  .hamburger:before,
  .hamburger:after {
    content: "";
    position: absolute;
    width: 35px;
    height: 2px;
    background: #FFF;
    transition: .5s;
    left: 0;

  }

  .hamburger:before {
    top: -10px;

  }

  .hamburger:after {
    top: 10px;

  }

  .nav-header.active .hamburger {
    background: rgba(0, 0, 0, 0);
  }

  .nav-header.active .hamburger:before {
    top: 0;
    transform: rotate(45deg);
    background: #FFF;
  }

  .nav-header.active .hamburger:after {
    top: 0;
    transform: rotate(135deg);
    background: #FFF;

  }

  .collapse-nav {
    position: fixed;
    width: 29rem;
    height: 100%;
    background: #156C42;
    top: 0;
    left: -250%;
    z-index: 99;
    display: block;
    padding-top: 120px;
    /* max-width: 290px; */
    transition: .3s;
  }

  .collapse-nav li {
    display: block;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
  }

  .main-menu {
    width: 100%;
    margin-bottom: 20px;
  }

  .extra-menu {
    width: 100%;
    display: inherit;
    padding: 0 15px;
  }

  .main-menu li a {
    padding: 5px 15px;
  }

  .donation {
    display: inline-block;
  }

  .logo {
    position: relative;
  }

  .collapse-nav.slide {
    left: 0;
  }

  .collapse-nav .nav-header {
    position: absolute;
    right: -56px;
    top: 48px;
    background: #156C42;
    width: 57px;
    height: 53px;
    border-radius: 0 7px 7px 0px;
  }

  /*hamburger-menu end*/
  .menu-trigger {
    display: block;
  }

  .menu-trigger.visible {
    content: "";
    width: 100%;
    height: 100%;
    z-index: 7;
    background: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
  }

  .extra-menu {
    position: absolute;
    right: 2rem;
    max-width: 76px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100%;
    padding: 0;
    padding-right: 15px;
    transform: translateY(-50%);
  }

  .menu-search {
    margin-left: 10px;
  }


  .single-grid-box {
    width: 50%;
  }

  .single-grid-box:nth-child(3) {
    border-left: 1px solid #156C42;
  }

  .single-grid-box:first-child,
  .single-grid-box:nth-child(2) {
    border-bottom: none;
  }

  .single-grid-box:nth-child(3) .box-contents h3::before {
    display: none;
  }
  .single-header-contact svg {
    font-size: 28px;
    padding: 7px;
    width: 3rem !important;
  }
}




@media(max-width: 767px) {

  .header-center {
    display: inherit;
  }

  .logo {
    max-width: 350px;
    margin: auto;
    margin-bottom: 20px;
  }

  .header-center-inner {
    padding: 20px 0;
  }

  .header-center-contacts {
    padding: 0;
    justify-content: start;
    -webkit-justify-content: flex-start;
    display: inherit;
    max-width: 220px;
    margin: auto;
    transition: .3s;
    height: auto;
  }

  .single-header-contact svg {
    font-size: 28px;
    padding: 7px;
  }

  .single-header-contact {
    padding-left: 41px;
    text-align: left;
    margin: auto;
    width: 100%;
    margin-bottom: 12px;
  }

  .single-header-contact p {
    font-size: 13px;
  }

  .header-contents p:last-child {
    font-size: 13px;
  }

  header.sticky .logo {
    max-width: 200px;
    margin-bottom: 0;
  }

  header.sticky .header-center-contacts {
    height: 0;
    padding: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
  }

  .donation a,
  .menu-search input {
    font-size: 12px;
  }

  .donation a {
    padding: 8px 22px;
  }

  .menu-search {
    padding: 8px;
    height: 36px;
  }

  .hero-contents h1 {
    font-size: 21px;
    line-height: 32px;
  }

  .hero-contents p {
    font-size: 14px;
    line-height: 22px;
  }

  .hero-contents li a {
    font-size: 13px;
    padding: 8px 35px;
  }

  .hero-contents ul li {
    margin-right: 10px;
  }

  .hero-contents ul li:last-child {
    margin: 0;
  }

  .hero-contents {
    padding-top: 200px;
    padding-bottom: 130px;
  }

  h2 {
    font-size: 24px;
  }

  p {
    font-size: 14px !important;
    line-height: 25px !important;
  }

  .single-counter {
    margin-bottom: 25px;
  }

  .conter-inner {
    padding: 70px 0;
  }

  #counter .row .col-md-4:last-child .single-counter {
    margin-bottom: 0;
  }

  .motive-head h2 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .motive-contents {
    padding-left: 0;
  }

  .single-news {
    margin-bottom: 30px;
  }

  .footer-policy {
    margin: 20px 0;
  }

  .copyright {
    text-align: center;
  }

  .socials {
    text-align: center;
  }

  footer .socials li:first-child {
    margin-left: 0;
  }

  .footer-logo img {
    max-width: 110px;
    margin: auto;
  }

  .footer-logo {
    text-align: center;
    margin-bottom: 20px;
  }

  .quick-link {
    margin: 30px 0;
  }

  #testimonial {
    padding: 40px 0;
  }

  .section-title {
    margin-bottom: 30px;
  }

  .single-grid-box {
    width: 100%;
    max-width: 350px;
    margin: auto;
    border-left: 1px solid;
  }

  .single-grid-box:nth-child(3) {
    border-bottom: 0;
  }

  .box-contents h3::before {
    display: none;
  }

  .banner-inner {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .banner-inner h1 {
    font-size: 26px;
    margin-bottom: 14px;
  }

  .map-iframe {
    position: inherit;
    width: 100%;
  }

  .map-iframe iframe {
    height: 100%;
    min-height: 300px;
  }

  .contact-form-inner {
    max-width: 1000%;
    padding: 60px 0;
  }
}